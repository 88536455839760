import React from 'react';
import { NumberField, Filter, ReferenceInput, SelectInput, ReferenceField, List, AutocompleteInput, Datagrid, EditButton, ShowButton, TextField } from 'react-admin';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const FiltroRol = (props) => (
    <Filter {...props}>
        <ReferenceInput
            source="admin"
            reference="atributos"
            filterToQuery={searchText => ({ nombre: searchText })}
            sort={{ field: 'nombre', order: 'asc' }}
            alwaysOn
            fullWidth
        >
            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

export const UsuarioList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props} 
            actions={permissions['usuario']  == 1 ? <ListAction /> : permissions['usuario']  == 2 ? <ListAction2 />: null} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<FiltroRol />} 
            bulkActionButtons={permissions['usuario'] == 1 ? <PostBulkActionButtons /> : null}
        >
            <Datagrid>
                <TextField source="nombres" />
                <TextField source="apellidos" />
                {/* <TextField source="tipo_documento" allowEmpty emptyValue="" sortable={false} /> */}
                <NumberField source="identificacion" sortable={false} />
                <TextField label="dynatest.uniqueEmail.email" source="username" sortable={false} />
                <ReferenceField source="admin" reference="atributos" linkType={false} >
                    <TextField source="adminNombre" />
                </ReferenceField>
                {/* <TextField label="Rol" source="adminNombre"/> */}
                {permissions['usuario'] == 1 || permissions['usuario'] == 2 || permissions['usuario'] == 4 ? <ShowButton/>: null}
                {permissions['usuario'] == 1 || permissions['usuario'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
};
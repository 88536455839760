
import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const AtributosList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
            actions={permissions['perfiles']  == 1 ? <ListAction /> : permissions['perfiles']  == 2 ? <ListAction2 />: null} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            bulkActionButtons={permissions['perfiles']  == 1 ? <PostBulkActionButtons />: null} >
            <Datagrid >
                <TextField source="nombre" />
                <TextField source="tipoRol" sortBy="tipo_rol" />
                {permissions['perfiles'] == 1 || permissions['perfiles'] == 2 || permissions['perfiles'] == 4 ? <ShowButton/>: null}
                {permissions['perfiles'] == 1 || permissions['perfiles'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)};


export const PROYECTO_SELECTED = 'PROYECTO_SELECTED';

export default (previousState = null, { type, proyecto }) => {
    if (type === PROYECTO_SELECTED) {
        return proyecto;
    }
    return previousState;
}


import React, {useState, Fragment} from 'react';
import { FormTab,required,TextInput, FormDataConsumer, SelectInput, DateInput, NumberInput, FileField, FileInput, SimpleForm, DateField } from 'react-admin';
import { Edit } from './../Edit';
import { withStyles, Grid } from '@material-ui/core';
import { TabbedForm } from './../TabbedForm';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../EstilosGrid';
import { validarConcordanciaFechas} from '../../helpers/validacionesInputs';


import config from '../../config/config'; 
let urlAppjs = config.UrlServer;

export const LicenciaClienteEdit = withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [nombrearchivo, setNombreArchivo] = useState('');
    
    const ValidacionArchivo=(value, props)=>{
        if(value){
            var fileExt = value.title.split('.').pop();
            const data = new FormData();
            var today = new Date();
            var horaact = today.getHours();
            var minact = today.getMinutes();
            if (horaact.toString().length <= 1) {
                horaact = "0" + horaact;
            }
        
            if (minact.toString().length <= 1) {
                minact = "0" + minact;
            }
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+"-" + minact;
            var date2=today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
            let nombrearchivo= "Licenciamiento/"+date+" - licencia_cliente."+fileExt;
            props.nombreArchivo = nombrearchivo;
            setNombreArchivo(nombrearchivo);
            data.append('Licenciamiento', value.rawFile, nombrearchivo);
            fetch(urlAppjs +'/guardarArchivoAdjunto/', {
                method: 'POST',
                body: data
            })
            .then(res=> res.json())
            .then(datos => {
                //console.log(datos)
            })
        }
    }
    return (
        <Edit {...props} title="Editar cliente" undoable={false} >
            <TabbedForm redirect="list" >
                <FormTab label="Información básica" submitOnEnter={false} >
                    <TextInput source="codigoCliente" label="Código del cliente" fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                    <TextInput source="nombreCliente" label="Nombre del cliente" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <DateInput source="fechaCreacion" label="Fecha de creación" fullWidth validate={[required()]} formClassName={classes.grid_cont4} disabled/>
                    <Grid container spacing={24}>  
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Información de licencia</Typography>                    
                            <Divider fullWidth style={{marginTop:'10px'}}/>  
                        </Grid>
                    </Grid>
                        <DateInput source="fechaActivacion"  label="Fecha de activación" fullWidth validate={[required(), validarConcordanciaFechas]} formClassName={classes.grid_cont4s}/>
                        <DateInput source="fechaFinalizacion" label="fecha de finalización"  fullWidth validate={[required(), validarConcordanciaFechas]} formClassName={classes.grid_cont4}/>    
                    <Grid container spacing={24}>  
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Datos administrativos</Typography>                    
                            <Divider fullWidth style={{marginTop:'10px'}}/>  
                        </Grid>
                    </Grid>  
                    <DateInput source="fechaPago"  label="Fecha de pago" fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/> 
                    <TextInput source="quienAutoriza" label="Quién autoriza en contabilidad" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <TextInput source="contrato" label="No. De contrato" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <Divider fullWidth style={{marginTop:'10px'}}/>  
                    <FileInput source="archivoCarga" label="Archivo" multiple={false} validate={[ValidacionArchivo]}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput source="nombreArchivo" defaultValue={nombrearchivo} fullWidth style={{display:'none'}} />
                    <FormDataConsumer>
                        {({ formData, dispatch, ...rest }) => {
                            if (typeof formData != 'undefined') {
                                var dataid = formData.id.replace("/licencia_clientes/", "");
                                if(dataid){
                                    formData.id = dataid;
                                }
                                console.log(formData)
                                if((formData.nombreArchivo != "") || (formData.nombreArchivo !=null)){
                                    return (       
                                        <a href={urlAppjs+"/"+formData.nombreArchivo} target="_blank" style={{textDecoration:"none"}}>
                                            <span>Descargar archivo</span>
                                        </a>
                                    )
                                }
                            }
                        }}
                    </FormDataConsumer>
                    
                </FormTab>
            </TabbedForm>
        </Edit>
    )
});
import React from 'react';
import { FormTab, TextInput, SelectInput } from 'react-admin';
import { Edit } from './../Edit';
import { withStyles, Grid } from '@material-ui/core';
import { TabbedForm } from './../TabbedForm';
import {
    required,

} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../EstilosGrid';
import UniqueAttribute from './../componentes/UniqueAttribute';
import { translate } from 'react-admin';

const AtributoTitulo = translate(({ record, ... props }) => {
    const { translate } = props;
    return <span>{translate('dynatest.resources.atributos.title.editTitle')} {record ? `"${record.nombre}"` : ''}</span>;
});

const opciones = [
    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
    { id: 2, name: 'dynatest.resources.atributos.select.acceso.acceso_parcial'  },
    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso'  },
    { id: 4, name: 'dynatest.resources.atributos.select.acceso.visualiza' }
];

export const AtributosEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    return (
        <Edit {...props} title={<AtributoTitulo />} undoable={false} >
            <TabbedForm redirect="list" >
                <FormTab label="dynatest.resources.atributos.formTabs.administracion" submitOnEnter={false} >
                    <UniqueAttribute
                        id="rol"
                        label="resources.atributos.fields.nombre"
                        source="nombre"
                        reference="atributos"
                        operation="edit"
                        formClassName={classes.grid_cont4s}
                    />
                    <SelectInput source="tipoRol" choices={[
                        { id: "Administrativo", name: 'dynatest.resources.atributos.select.tipo_rol.administrativo' },
                        { id: "Campo", name: 'dynatest.resources.atributos.select.tipo_rol.campo' },
                        { id: "Oficina", name: 'dynatest.resources.atributos.select.tipo_rol.oficina' },
                    ]} fullWidth validate={[required()]} sort={{ field: 'id', order: 'ASC' }} formClassName={classes.grid_cont4} />

                    <SelectInput source="usuario" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput source="perfiles" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configGeneral')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                    </Grid>
                    <SelectInput source="licencias" label="Licencias" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}));
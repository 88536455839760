import React, { useState, useEffect } from 'react'
import config from '../../config/config';

let urlAppjs = config.UrlServer;

const LogoProyecto = (...props) => {
    let proyecto = props[0].propsComponent.proyecto;
    const [imagen, setImagen] = useState();

    if (proyecto) {
        imagenlogo();
    }

    useEffect(() => {
        imagenlogo();
    }, []);

    function imagenlogo() {
        let proyectSelect = window.localStorage.id_project;
        if (proyectSelect) {
            fetch(urlAppjs + "/ImagenLogo/" + proyectSelect, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
                .then(res => res.json())
                .then(response => {
                    setImagen(response);
                });
        } else {
            setImagen('');
        }
    }

    return (
        <img id="imgProject" src={imagen} height="30px">
        </img>
    )
}

export default LogoProyecto;
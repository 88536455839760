import React, { Component } from 'react';
import { Layout } from 'react-admin';
import PimsAppBar from './PimsAppBar';
import PimsMenu from './PimsMenu';


const PimsLayout = (props) => <Layout
    {...props}
    appBar={PimsAppBar}
    menu={PimsMenu}
/>;

export default PimsLayout;
import React from 'react';
import { translate } from 'react-admin';
//------ VALIDACIÓN LONGITUD Y LATITUD ----
export const validarDecimales = (value, props, translate) => {

    if (value !== undefined && value % 1 !== 0) {
        if (!/^[+-]?([0-9]+([.][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }
    }
}

export const validarDecimales2 = (value, props, translate) => {
    if (value !== undefined && value % 1 !== 0) {
        if (!/^[+-]?([0-9]+([.][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }
    }
}

export const validarDecimales3 = (value, props, translate) => {

    if (value !== undefined && value % 1 !== 0) {
        if (!/^[+-]?([0-9]+([.][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }
    }
}

export const validarDecimales4 = (value, props, translate) => {
    if (value !== undefined && value % 1 !== 0) {
        if (!/^[+-]?([0-9]+([.][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }
    }
}

// ------ VALIDACIÓN DE ABSCISAS -------


export const abValidationInicio = (value, props, translate) => {

    if (value) {
        /*VALORES ABS INICIO*/
        let AbInicioBase = document.getElementById('IdAbInicio').parentNode.firstChild.innerHTML;
        let ingresoAbInicio = document.getElementById('ingresoAbInicio').value;
        let separadorInicio = ingresoAbInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("K");
        let seAbInicioBase = AbInicioBase.split(".");
        let AbFinBase = document.getElementById('IdAbFin').parentNode.firstChild.innerHTML;
        let seAbFinBase = AbFinBase.split(".");

        /*VALIDACIONES PR INICIO*/
        if (parseInt(separadorInicio1[1]) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1]) > parseInt(AbFinBase)) {

            return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorInicio1[1]) === parseInt(AbInicioBase)) {
            if (parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorInicio1[1]) === parseInt(seAbFinBase[0])) {

            if (parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
    }
}

export const abValidationFin = (value, props, translate) => {

    if (value) {
        /*VALORES AB FIN */
        let AbFinBase = document.getElementById('IdAbFin').parentNode.firstChild.innerHTML;
        let ingresoAbFin = document.getElementById('ingresoAbFin').value;
        let separadorFin = ingresoAbFin.split("+");
        let separadorFin1 = separadorFin[0].split("K");
        let seAbFinBase = AbFinBase.split(".");
        let AbInicioBase = document.getElementById('IdAbInicio').parentNode.firstChild.innerHTML;
        let seAbInicioBase = AbInicioBase.split(".");
        /*VALIDACIONES AB FIN*/
        if (parseInt(separadorFin1[1]) > parseInt(AbFinBase) || parseInt(separadorFin1[1]) < parseInt(AbInicioBase)) {
            return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorFin1[1]) === parseInt(AbFinBase)) {
            if (parseInt(separadorFin[1]) > parseInt(seAbFinBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorFin1[1]) === parseInt(seAbInicioBase[0])) {
            if (parseInt(separadorFin[1]) < parseInt(seAbInicioBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
    }
}

export const abValidationInicioEdit = (value, props, translate) => {

    if (document.getElementById('IdAbInicio') && value) {
        /*VALORES ABS INICIO*/
        let AbInicioBase = document.getElementById('IdAbInicio').parentNode.firstChild.innerHTML;
        let ingresoAbInicio = document.getElementById('ingresoAbInicio').value;
        let separadorInicio = ingresoAbInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("K");
        let seAbInicioBase = AbInicioBase.split(".");
        let AbFinBase = document.getElementById('IdAbFin').parentNode.firstChild.innerHTML;
        let seAbFinBase = AbFinBase.split(".");
        /*VALIDACIONES PR INICIO*/
        if (parseInt(separadorInicio1[1]) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1]) > parseInt(AbFinBase)) {

            return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorInicio1[1]) === parseInt(AbInicioBase)) {
            if (parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorInicio1[1]) === parseInt(seAbFinBase[0])) {

            if (parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
    }
}

export const abValidationFinEdit = (value, props, translate) => {

    if (document.getElementById('IdAbFin') && value) {
        /*VALORES AB FIN */
        let AbFinBase = document.getElementById('IdAbFin').parentNode.firstChild.innerHTML;
        let ingresoAbFin = document.getElementById('ingresoAbFin').value;
        let separadorFin = ingresoAbFin.split("+");
        let separadorFin1 = separadorFin[0].split("K");
        let seAbFinBase = AbFinBase.split(".");
        let AbInicioBase = document.getElementById('IdAbInicio').parentNode.firstChild.innerHTML;
        let seAbInicioBase = AbInicioBase.split(".");
        /*VALIDACIONES AB FIN*/
        if (parseInt(separadorFin1[1]) > parseInt(AbFinBase) || parseInt(separadorFin1[1]) < parseInt(AbInicioBase)) {
            return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorFin1[1]) === parseInt(AbFinBase)) {
            if (parseInt(separadorFin[1]) > parseInt(seAbFinBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
        if (parseInt(separadorFin1[1]) === parseInt(seAbInicioBase[0])) {
            if (parseInt(separadorFin[1]) < parseInt(seAbInicioBase[1]))

                return translate.translate('resources.validaciones.rangoEntre') + AbInicioBase + " y " + AbFinBase;
        }
    }
}

export const lngIni = (value, props, translate) => {

    if (document.getElementById('lngMin') && document.getElementById('lngMax') && value) {
        /*VALORES LNG INICIO*/
        let lngMin = document.getElementById('lngMin').parentNode.firstChild.innerHTML;
        let lngMax = document.getElementById('lngMax').parentNode.firstChild.innerHTML;
        if (lngMax !== '') {
            if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {

                return translate.translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
            }
        }

    }
}

export const latIni = (value, props, translate) => {

    if (document.getElementById('latMin') && document.getElementById('latMax') && value) {
        /*VALORES LNG INICIO*/
        let latMin = document.getElementById('latMin').parentNode.firstChild.innerHTML;
        let latMax = document.getElementById('latMax').parentNode.firstChild.innerHTML;
        if (latMax !== '') {
            if (parseFloat(value) > parseFloat(latMin) || parseFloat(value) < parseFloat(latMax)) {

                return translate.translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
            }
        }

    }
}

export const validarDecimales5 = (props, translate) => {
    let latitudFin = document.getElementById('latitudFin').value;
    if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(latitudFin)) {
        return translate.translate('resources.validaciones.coordenadasDecimal');
    }
}


//VALIDAR QUE LOS TAMAÑOS DE LOS PR NO SEAN IGUALES
export const validarLogicaPrFinCreate = (value, props, translate) => {
    if (value !== undefined) {

        let prInicio = document.getElementById('ingresoPrInicio').value;
        prInicio = prInicio.split('PR')
        if (prInicio[1] !== undefined) {
            prInicio = prInicio[1].split('+')

            value = value.split('PR')
            value = value[1].split('+')

            if (parseInt(value[0]) < parseInt(prInicio[0])) {
                return translate.translate('resources.validaciones.prFin')
            }
            if (parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1])) {
                return translate.translate('resources.validaciones.prFin')
            }
            if (parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1])) {
                return translate.translate('resources.validaciones.prFin')
            }

        }



    }
}

export const ValorMaximoPrCreate = (value, props, translate) => {
    if (value !== undefined) {
        value = value.split("PR")
        if (value[1] !== undefined) {
            value = value[1].split("+")
            if (value[0] > 999) {
                return translate.translate('resources.validaciones.mayorPR9')
            }
        }
    }
}


export const validarLogicaPrFinEdit = (props, translate) => {
    let prInicio = document.getElementById('ingresoPrInicio').value;
    prInicio = prInicio.split('PR')
    if (prInicio[1] !== undefined) {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoPrFin').value;
        prFin = prFin.split('PR')
        prFin = prFin[1].split('+')

        if (parseInt(prFin[0]) < parseInt(prInicio[0])) {
            return translate.translate('resources.validaciones.prFin')
        }
        if (parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1])) {
            return translate.translate('resources.validaciones.prFin')
        }
        if (parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1])) {
            return translate.translate('resources.validaciones.prFin')
        }
    }
}

export const ValorMaximoPrEditInicio = (props,translate) => {

    let value = document.getElementById('ingresoPrInicio').value;
    value = value.split("PR")
    if (value[1] !== undefined) {
        value = value[1].split("+")

        if (value[0] > 999) {
            return translate.translate('resources.validaciones.mayorPR9')
        }
    }
}

export const ValorMaximoPrEditFin = (props, translate) => {

    let value = document.getElementById('ingresoPrFin').value;
    value = value.split("PR")
    if (value[1] !== undefined) {
        value = value[1].split("+")

        if (value[0] > 999) {
            return translate.translate('resources.validaciones.mayorPR9')
        }
    }
}

//Validaciones logicas de las ABscisas
export const validarLogicaAbsFinCreate = (value, props, translate) => {

    if (value !== undefined) {

        let prInicio = document.getElementById('ingresoAbInicio').value;
        prInicio = prInicio.split('K')
        if (prInicio[1] !== undefined) {
            prInicio = prInicio[1].split('+')

            value = value.split('K')
            value = value[1].split('+')

            if (parseInt(value[0]) < parseInt(prInicio[0])) {
                return translate.translate('resources.validaciones.abscisaFin')
            }
            if (parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1])) {
                return translate.translate('resources.validaciones.abscisaFin')
            }
            if (parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1])) {
                return translate.translate('resources.validaciones.abscisaFin')
            }
        }

    }
}

export const validarLogicaAbsFinEdit = (props, translate) => {

    let prInicio = document.getElementById('ingresoAbInicio').value;
    prInicio = prInicio.split('K')
    if (prInicio[1] !== undefined) {
        prInicio = prInicio[1].split('+')

        let prFin = document.getElementById('ingresoAbFin').value;
        prFin = prFin.split('K')
        prFin = prFin[1].split('+')

        if (parseInt(prFin[0]) < parseInt(prInicio[0])) {
            return translate.translate('resources.validaciones.abscisaFin')
        }
        if (parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1])) {
            return translate.translate('resources.validaciones.abscisaFin')
        }
        if (parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1])) {
            return translate.translate('resources.validaciones.abscisaFin')
        }

    }


}
export const ValorMaximoAbsEditInicio = (props, translate) => {

    let value = document.getElementById('ingresoAbInicio').value;
    value = value.split("K")
    if (value[1] !== undefined) {
        value = value[1].split("+")

        if (value[0] > 9999) {
            return translate.translate('resources.validaciones.mayorK9');
        }
    }

}

export const ValorMaximoAbsEditFin = (props, translate) => {

    let value = document.getElementById('ingresoAbFin').value;
    value = value.split("K")
    if (value[1] !== undefined) {
        value = value[1].split("+")

        if (value[0] > 9999) {
            return translate.translate('resources.validaciones.mayorK9');
        }
    }
}

export const ValorMaximoAbsCreate = (value, props, translate) => {
    if (value !== undefined) {
        value = value.split("K")
        if (value[1] !== undefined) {
            value = value[1].split("+")
            if (value[0] > 999) {
                return translate.translate('resources.validaciones.mayorK9');
            }
        }
    }
}


/*VALIDACION GEOREFERENCIA SEGMENTOS */
export const latMininima = (value, props, translate) => {
    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');
    if (latMax) {
        latMin = latMin.parentNode.firstChild.innerHTML;
        latMax = latMax.parentNode.firstChild.innerHTML;
        if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
            return translate.translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
        }
    }
}

export const lngMininima = (value, props, translate) => {

    /*VALORES ABS FIN */

    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');

    if (lngMax) {
        lngMin = lngMin.parentNode.firstChild.innerHTML;
        lngMax = lngMax.parentNode.firstChild.innerHTML;
        if (parseFloat(value) < parseFloat(lngMax) || parseFloat(value) > parseFloat(lngMin)) {

            return translate.translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
        }
    }
}

export const latMininimaEdit = (value, props, translate) => {

    /*VALORES ABS FIN */

    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');

    if (latMax) {
        latMin = latMin.value;
        latMax = latMax.value;
        if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {

            return translate.translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
        }

    }
}


export const lngMininimaEdit = (value, props, translate) => {

    /*VALORES ABS FIN */

    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');

    if (lngMax) {
        lngMin = lngMin.value;
        lngMax = lngMax.value;
        if (parseFloat(value) < parseFloat(lngMax) || parseFloat(value) > parseFloat(lngMin)) {

            return translate.translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
        }
    }
}



export const validarDosDecimales = (value, props, translate) => {

    if (value) {
        if (value !== 0 && value !== 'No aplica') {
            if (value % 1 !== 0) {
                let pattern = new RegExp("^[0-9]+([.][0-9]{2})$");

                if (!pattern.test(value)) {
                    let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if (!patterns.test(value)) { return translate.translate('resources.validaciones.decimales2'); }
                }
            }
        }
        // debugger
        // if(value)
        // {
        //     let pattern =new RegExp("/^([0-9])*$/");
        //     if(!pattern.test(value)){return "El valor ingresado debe ser un decimal";}
        // }
    }
}

export const validarUnDecimal = (value, props, translate) => {

    if (value) {
        if (value !== 0) {
            if (value % 1 !== 0) {
                var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");

                if (!pattern.test(value)) {
                    var patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if (!patterns.test(value)) { return translate.translate('resources.validaciones.decimales1'); }
                }
            }
        }
    }
}

export const validarPorcentaje = (value, props, translate) => {
    value = value.split('%');
    if (!/^[+-]?([0-9]+)*$/.test(value[0])) {
        return translate.translate('resources.validaciones.entero');
    }
}

/* VALIDAR RANGOS DE PR ENTRE SEGMENTOS */

export const validarPrEntreSegmento = (value, props, translate) => {

    let prIngreso = document.getElementById('ingresoPrInicio');
    let valor = document.getElementById('ingresoPrFin');
    if (prIngreso) {
        prIngreso = prIngreso.value;
        prIngreso = prIngreso.split('PR');
        prIngreso = prIngreso[1].split('+');
        prIngreso = prIngreso[0] + prIngreso[1];

        valor = valor.value;
        valor = valor.split('PR');
        valor = valor[1].split('+')
        valor = valor[0] + valor[1];

        let resta = parseInt(valor) - parseInt(prIngreso);

        if (resta > 1999) {
            return translate.translate('resources.validaciones.distanciaPr')
        } else if (resta < 1000) {
            return translate.translate('resources.validaciones.distanciaPrMenor')
        }
    }
}

export const validarAbsEntreSegmento = (props, translate) => {

    let AbsIngreso = document.getElementById('ingresoAbInicio');
    let valor = document.getElementById('ingresoAbFin');
    if (AbsIngreso) {
        AbsIngreso = AbsIngreso.value;
        AbsIngreso = AbsIngreso.split('K');
        AbsIngreso = AbsIngreso[1].split('+');
        AbsIngreso = AbsIngreso[0] + AbsIngreso[1];

        valor = valor.value;
        valor = valor.split('K');
        valor = valor[1].split('+')
        valor = valor[0] + valor[1];

        let resta = parseInt(valor) - parseInt(AbsIngreso);

        if (resta > 1999) {
            return translate.translate('resources.validaciones.distanciaAbscisa');
        }
        // else if(resta < 1000)
        // {
        //     return 'La distancia entr las Abscisas deber ser de al menos  de 1.000'
        // }
    }


}

export const validarSeisDecimales = (value, props, translate) => {

    if (value) {
        if (value !== 0) {
            if (value % 1 !== 0) {
                var pattern = new RegExp("^[+-]?([0-9]+([.][0-9]+))?$");
                if (!pattern.test(value)) { return translate.translate('resources.validaciones.coordenadaValida'); }
            }
        }
    }
}


export const Entero = (value, props, translate) => {
    if (value !== undefined && value !== null) {
        if (!/^([0-9]+)*$/.test(value)) {
            return translate.translate('resources.validaciones.entero');
        }
    }
}

//-----------Validar fechas: que la inicial no sea mayor que la final y viceversa

export const validarFechaDesdehoy = (props, translate) => {
    let fechaInicio = document.getElementById("undefined_fechaInicio_date_input").value;
    let today = new Date();
    let month = "";
    let day = "";

    if ((today.getMonth() + 1) < 10) {
        month = "0" + (today.getMonth() + 1)
    } else {
        month = (today.getMonth() + 1)
    }

    if (today.getDate() < 10) {
        day = "0" + (today.getDate())
    } else {
        day = (today.getDate())
    }

    today = today.getFullYear() + "-" + month + "-" + day;
    if (fechaInicio != "") {
        if (fechaInicio < today) {
            return translate.translate('resources.validaciones.planComenzar2');
        }

    }

}
export const validarConcordanciaFechas = (props) => {
    let fechaInicio = document.getElementById("fechaActivacion").value;
    let fechaFin = document.getElementById("fechaFinalizacion").value;
    let today = new Date();
    let month = "";
    let day = "";

    if ((today.getMonth() + 1) < 10) {
        month = "0" + (today.getMonth() + 1)
    } else {
        month = (today.getMonth() + 1)
    }

    if (today.getDate() < 10) {
        day = "0" + (today.getDate())
    } else {
        day = (today.getDate())
    }

    today = today.getFullYear() + "-" + month + "-" + day;

    if (fechaInicio != "" && fechaFin != "") {
        if (fechaInicio > fechaFin) {
            return "La fecha de activacion no puede ser mayor, a la fecha de finalización";
        }
    }

}

export const maxCostMant = (value, props, translate) => {
    if (value.toString().length > 15) {
        return translate.translate('resources.validaciones.millardos');
    }
}

export const maxLength50 = (value, props, translate) => {
    if (value.toString().length > 50) {
        return translate.translate('resources.validaciones.max50');
    }
}

export const maxLength40 = (value, props, translate) => {
    if (value.toString().length > 40) {
        return translate.translate('resources.validaciones.max50');
    }
}
export const isFechaInvalida = (value, props, translate) => {
    if (value) {
        let dateActual = new Date();
        dateActual = dateActual.toLocaleString("en-US", { timeZone: 'UTC' });
        dateActual = dateActual.split(",")[0].split("/");
        let dateSeleccionada = new Date(value);
        dateSeleccionada = dateSeleccionada.toLocaleString("en-US", { timeZone: 'UTC' });
        dateSeleccionada = dateSeleccionada.split(",")[0].split("/");

        if(parseInt(dateSeleccionada[2]) < 2000) {
            return translate.translate('resources.validaciones.fechaAPartir');
        } 
        
        if (parseInt(dateSeleccionada[2]) > parseInt(dateActual[2])){
            return translate.translate('resources.validaciones.fechaFutura');
        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) > parseInt(dateActual[0])
        ){
            return translate.translate('resources.validaciones.fechaFutura');
            
        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
            parseInt(dateSeleccionada[1]) > parseInt(dateActual[1])
        ){
            return translate.translate('resources.validaciones.fechaFutura');
                            
        }
    }
};

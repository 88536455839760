import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, SelectField } from 'react-admin';
import { Show } from './../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const opciones = [
    { id: 0, name: 'dynatest.resources.atributos.select.acceso.noDefinido' },
    { id: null, name: 'dynatest.resources.atributos.select.acceso.noDefinido' },
    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
    { id: 2, name: 'dynatest.resources.atributos.select.acceso.acceso_parcial' },
    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso'},
    { id: 4, name: 'dynatest.resources.atributos.select.acceso.visualiza' }
];

const AtributoTitulo =  translate(({ record, ...props }) => {
    const { translate } = props;
    return <span>{translate('dynatest.resources.atributos.title.showTitle')} {record ? `"${record.nombre}"` : ''}</span>;
});
export const AtributosShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
    <Show  {...props} title={<AtributoTitulo />} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.atributos.formTabs.administracion" submitOnEnter={false} >
                <TextField source="nombre" fullWidth />
                <TextField source="tipoRol" />
                <SelectField choices={opciones} source="usuario" />
                <SelectField choices={opciones} source="perfiles" />
                {/* <SelectField choices={opciones} source="asigclaves" /> */}
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.configGeneral')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="licencias" />
            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.auditoria">
                <DateField source="createdAt" showTime />
                <TextField source="createdBy"  />
                <div></div>
                <DateField source="updatedAt" showTime />
                <TextField source="updatedBy"  />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
    }));
import { withStyles } from '@material-ui/core';

export const styles = {
    grid_cont1:  { display: 'inline-block', width: '8%' , marginLeft: '20px'},
    grid_cont1s: { display: 'inline-block', width: '8%' },
    grid_cont2:  { display: 'inline-block', width: '15%' , marginLeft: '20px'},
    grid_cont2s: { display: 'inline-block', width: '15%' },
    grid_cont3Right: { display: 'inline-block', width: '22%' , marginRight: "2%"},
    grid_cont3s: { display: 'inline-block', width: '25%' },
    grid_cont3:  { display: 'inline-block', width: '24%' , marginLeft: '1%'},
    grid_cont4s: { display: 'inline-block', width: '32%' ,marginBottom: '1%'},
    grid_cont4:  { display: 'inline-block', width: '33%' , marginLeft: '1%',marginBottom: '20px'},
    grid_cont4Rigth: { display: 'inline-block', width: '31%' ,marginBottom: '20px', marginRight : "2%"},
    grid_cont5:  { display: 'inline-block', width: '48%' , marginLeft: '20px'},
    grid_cont5s: { display: 'inline-block', width: '48%' },
    grid_cont6:  { display: 'inline-block', width: '49%' , marginLeft: '1%'},
    grid_cont6s: { display: 'inline-block', width: '49%' },
    grid_cont6Right: { display: 'inline-block', width: '48%', marginRight: "2%" },
    grid_cont12:  { display: 'inline-block', width: '100%',marginBottom: '20px' },
    grid_cont12s: { display: 'inline-block', width: '100%',marginBottom: '20px' , marginLeft: '20px'},
    grid_cont_div: { display: 'inline-block', width: '100%',marginBottom: '20px' , marginTop : '20px'},
    ocultar:{display:"none"}, 
    infoAdicional: { color : "grey"},
    coord :{marginTop : '50px'},
    table: {minWidth: 650,},
    grid_cont4show: { display: 'inline-grid', width: '31%' , marginBottom: '20px'},
    infoAdicional:{color:"grey"},
    textAdicional:{color:"grey", fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, fontSize: 12, marginBottom: '8px', marginTop : '16px'},

    grid_cont6_f: { display: 'inline-block', width: '50%' },
    grid_cont6_s: { display: 'inline-block', width: '49%', marginLeft: '1%'},
    grid_cont4_f: { display: 'inline-block', width: '32%' },
    grid_cont4_s: { display: 'inline-block', width: '33%', marginLeft: '1%' },
    grid_cont3_f: { display: 'inline-block', width: '25%' },
    grid_cont3_s: { display: 'inline-block', width: '24%' , marginLeft: '1%'},

    /*ESTILOS OTRO_ELLEMENTOS */
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' },
    first_field2: { display: 'inline-block', width: '40%' },
    separador: { marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0 },
    subtittle: { marginTop: "15px", marginBottom: "15px" },
    ocultar: { display: 'none' },

    //alineadores de textos
    textToRight : {textAlign: 'right'},
    //Alinear reference a la derecha
    referenceToleft : {width: "32%", marginRight:"5px"},
    //Alinear tipo de superficie
    alignReference : {width: "250px", marginRight:"10px"},
    //Alinear campo Subestructura
    left : {width: "23%", marginRight:"5px"},
    toLeft : {width: "230px", marginRight:"10px"},
    //autocomplete
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'},

    /*ESTILOS POLITICAS DE MANTENIMIENTO */
    fieldCol1: { display: 'inline-block', width: '7%', marginRight: '1%' },
    fieldCol2: { display: 'inline-block', width: '15%', marginRight: '1%' },
    fieldCol3: { display: 'inline-block', width: '24%', marginRight: '1%' },
    fieldCol4: { display: 'inline-block', width: '32%', marginRight: '1%' },
    fieldCol5: { display: 'inline-block', width: '39%', marginRight: '1%' },
    fieldCol6: { display: 'inline-block', width: '49%', marginRight: '1%' },
    fieldCol7: { display: 'inline-block', width: '57%', marginRight: '1%' },
    fieldCol9: { display: 'inline-block', width: '73%', marginRight: '1%' },
    fieldCol11: { display: 'inline-block', width: '91%', marginRight: '1%' },
    fieldCol12: { display: 'inline-block', width: '99%', marginRight: '1%' },

    /*ESTILOS PARA TABLECELL POLITICAS DE MANTENIMIENTO */
    TbCellCol1: {padding: "5px 10px 4px 10px", width: '8%'},
    TbCellCol2: {padding: "5px 10px 4px 10px", width: '16%'},
    TbCellCol3: {padding: "5px 10px 4px 10px", width: '25%'},
    TbCellCol4: {padding: "5px 10px 4px 10px", width: '33%'},
    TbCellCol6: {padding: "5px 10px 4px 10px", width: '50%'},
    TbCellCol9: {padding: "5px 10px 4px 10px", width: '75%'},
    TbCellCol11: {padding: "5px 10px 4px 10px", width: '81%'},
    TbCellCol12: {padding: "5px 10px 4px 10px", width: '100%'},
};
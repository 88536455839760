import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, Error, NotFound } from 'react-admin';

export default () => (
    <Card>
        <NotFound/>
        <br></br>
        <br></br>
        <br></br>
    </Card>
    
);
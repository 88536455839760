import {CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE} from "react-admin";

import {stopSubmit} from 'redux-form';

import {put, takeEvery} from "redux-saga/effects";

export default function* errorSaga() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudEditFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudEditFailure);
}

export function* crudEditFailure(action) {
  var error = action.error;
  if( error ){
      var json = {};
      if(typeof error === "string"){
        var errorSpl = error.split('\n');
        errorSpl.forEach(
          function(item) {              
              var itemSpl = item.split(':');
              if( itemSpl.length > 1 ){
                  json[itemSpl[0]] = itemSpl[1];
              }
          }
        );      
      }
      else if (typeof error === "object"){
        json = error;
      }
      yield put(stopSubmit('record-form', json));
  }  
}

import React, { Component } from 'react';
import { AppBar, UserMenu, MenuItemLink, Button, translate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Logo from './logo.png';
import LogoProyecto from './resources/configuracion_sistema/logoProyecto';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import config from './config/config';
let urlAppjs = config.UrlServer;

/**
 * TODO: mostrar aca el proyecto, etc, seleccionado
 */

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    media: {
    width: '20%',
    borderRadius: '0',
    //fontFamily: "object-fit: none",
    //marginRight: '-1
  },
    spacer: {
        flex: 1,
    },
    noshow:{display: 'none'}
};

function HelpCenter(props) {
    return (
        <SvgIcon {...props}>
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6.99 15c-.7 0-1.26-.56-1.26-1.26 0-.71.56-1.25 1.26-1.25.71 0 1.25.54 1.25 1.25-.01.69-.54 1.26-1.25 1.26zm3.01-7.4c-.76 1.11-1.48 1.46-1.87 2.17-.16.29-.22.48-.22 1.41h-1.82c0-.49-.08-1.29.31-1.98.49-.87 1.42-1.39 1.96-2.16.57-.81.25-2.33-1.37-2.33-1.06 0-1.58.8-1.8 1.48l-1.65-.7C9.01 7.15 10.22 6 11.99 6c1.48 0 2.49.67 3.01 1.52.44.72.7 2.07.02 3.08z" />
        </SvgIcon>
    );
}

const MyUserMenu = props => {
    const { translate } = props;
    
    const handleListItem2 = () => {
        let idiomaUser = window.localStorage.getItem('idioma');
        fetch(urlAppjs + "/idiomas?region=" + idiomaUser, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((success) => {
            if (success['hydra:member'].length > 0) {
                window.open(urlAppjs + "/Manuales/" + success['hydra:member'][0].manual,'_blank');
            }else{
                window.open(urlAppjs + "/Manuales/Manual de usuario Español.pdf",'_blank');
            }
        });
    }

    return(
        <div id='userMenu' style={{display:'contents'}}>
            <IconButton onClick={handleListItem2}><HelpCenter style={{color:'#fafafa'}} /></IconButton>
            <UserMenu id="userDivMenu" {...props} />
        </div>
    )
};

const PimsAppBar = 
    translate(withStyles(styles)(
        ({ classes, ...props }) => {
            const { translate } = props;
            return(
                <AppBar id="AppBarId" {...props} style={{backgroundColor:' #0F78BC'}} userMenu={<MyUserMenu translate={translate} />} >
                    <Typography
                        variant="title"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    /> 
                    <div id='projectSelectedAppBar'>{props.proyecto != null ? props.proyecto.codigo+' :: '+props.proyecto.nombre : ( window.localStorage.codigoProyecto != undefined) ? window.localStorage.codigoProyecto +' :: '+window.localStorage.nombreProyecto : ''}</div>    
                    <div className={classes.noshow} id="idGlobalProyecto">{props.proyecto != null ? props.proyecto.id : ''}</div>          
                    <span className={classes.spacer} />
                    <LogoProyecto propsComponent={props} />                               
                    <Avatar src={Logo} className={classes.media} id="img-logo"/>
                </AppBar>
            )
        }
    ));

const mapStateToProps = 
    state => 
        ({ 
        proyecto: state.proyecto, 
        ruta: state.ruta,
        unidadFuncional: state.unidadFuncional,
    });

export default connect(mapStateToProps, {})(PimsAppBar);

import isObject from 'isplainobject';
import { fetchHydra } from '@api-platform/admin';

// define REST methods
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';
import { is } from 'redux-saga/utils';


/**d
 * Local cache containing embedded documents.
 * It will be used to prevent useless extra HTTP query if the relation is displayed.
 *
 * @type {Map}
 */
export const reactAdminDocumentsCache = new Map();

class ReactAdminDocument {

    constructor(obj) {
        Object.assign(this, obj, {
            originId: obj.id ? obj.id : obj['@id'],
            id: obj['@id'],
        });
    }

    /**
     * @return {string}
     */
    toString() {
        return `[object ${this.id}]`;
    }
}


/*
 @param entrypoint -> API URL. It can be testing localhost or deployment server
 @param http client that is an object from react hydra

*/
export default (entrypoint, httpClient = fetchHydra) => {
    const loadFilterParams = (url, params) => {

        if (params.filter) {
            Object.keys(params.filter).forEach(key => {
                let abNum = '';
                let filterValue = params.filter[key];
                let stringA = key.substr(0, 2);


                if (!filterValue) {
                    return;
                }

                if (!isObject(filterValue)) {
                    if (!(filterValue instanceof Array)) {

                        url.searchParams.set(key, params.filter[key]);
                        // if(stringA === 'ab'){

                        //     Object.keys(filterValue).forEach(keyAb =>{
                        //         Object.values(filterValue).forEach(subAb =>{

                        //             abNum = subAb.substr(1, subAb.lastIndexOf('+')-1)+'.'+subAb.substr(subAb.lastIndexOf('+')+1,subAb.length);
                        //             filterValue.lt = abNum;
                        //             url.searchParams.set(`${key}[${keyAb}]`,abNum);   
                        //         })    
                        //     })

                        // }else{
                        //     url.searchParams.set(key, params.filter[key]);
                        // }                        

                    } else {
                        Object.keys(filterValue).forEach(subKey => {

                            // let keyo = toString(key);
                            url.searchParams.set(`${key}[${subKey}]`, filterValue[subKey],);

                        });
                    }
                    return;
                } else {
                    Object.keys(filterValue).forEach(subKey => {

                        // let keyo = toString(key);
                        url.searchParams.set(`${key}[${subKey}]`, filterValue[subKey],);

                    });
                }

            });
        }

    }

    const ArrayFilter = (url, params) => {
        if (params.filter) {
            Object.keys(params.filter).forEach(key => {
                const filterValue = params.filter[key];
                if (!filterValue) {
                    return;
                }
                if (!isObject(filterValue)) {
                    url.searchParams.set(key, params.filter[key]);
                    return;
                }
                Object.keys(filterValue).forEach(subKey => {

                    // let keyo = toString(key);
                    url.searchParams.set(`${subKey}[${key}]`, filterValue[subKey],);

                });
            });
        }
    }

    //retrieved data will be stored in this var to render it through react
    const toReactAdmin = (document) => {
        var toMerge = {};
        if (!document) {
            return;
        }

        if (document.hasOwnProperty("0")) {
            toMerge = { ...document["0"], ...document }
            delete toMerge["0"];
            document = toMerge;
            console.log(document);
        }

        //console.log("toReactAdmin("+document['@id']+")");
        if (document['@id']) {
            document = new ReactAdminDocument(document);
            reactAdminDocumentsCache[document['@id']] = document;
        }
        Object.keys(document).forEach(key => {
            //console.log("toReactAdmin("+document['@id']+") :: procesando ["+key+"] ");
            if (!document[key]) {
                //console.log("toReactAdmin("+document['@id']+") :: procesando ["+key+"] es nulo");
                return;
            }
            if (isObject(document[key]) && document[key]['@id']) {
                // console.log("toReactAdmin("+document['@id']+") :: procesando ["+key+"] es un objeto");
                document[key + "_"] = toReactAdmin(document[key]);
                document[key] = document[key + "_"]["@id"];
                return;
            }
            if (Array.isArray(document[key]) && document[key].length
                && isObject(document[key][0]) && document[key][0]['@id']) {
                //
                // console.log("toReactAdmin("+document['@id']+") :: procesando ["+key+"] es un array");
                document[key] = document[key].map(toReactAdmin);
            }
        });
        return document;
    }

    /**
     * Query a data provider and return a promise for a response
     *
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the action type
     * @returns {Promise} the Promise for a response
     */
    const fetchApi = (type, resource, params) => {
        const entrypointUrl = new URL(entrypoint, window.location.href);
        //
        let url = '';
        let options = {};
        /* 
        Params sent in react object are used here
            @param: sort={{ field: 'desired_field_to_order', order: 'desired SQL order' }}
        the resulted object are stored in var toReactAdmin, the related react object will render the inf
        */
        switch (type) {
            case GET_MANY: { //console.log(type);               
                return Promise.all(
                    params.ids.map(
                        id => {
                            if (isObject(id)) {
                                return Promise.resolve({ data: id });
                            }
                            else {
                                if (reactAdminDocumentsCache[id]) {
                                    return Promise.resolve({ data: reactAdminDocumentsCache[id] });
                                }
                                else {
                                    return fetchApi(GET_ONE, resource, id);
                                }
                            }
                        }
                    )
                ).then(
                    responses => ({ data: responses.map(({ data }) => data) })
                );
            }
            case GET_MANY_REFERENCE: { //console.log(type);
                url = new URL(`${entrypoint}/${resource}`, entrypointUrl);
                const { sort: { field, order }, } = params;
                if (order) url.searchParams.set(`order[${field}]`, order);
                loadFilterParams(url, params);
                // console.log("("+type+", "+resource+", "+params+") :: "+url);
                return httpClient(url, options).then(
                    response => Promise.resolve(
                        response.json['hydra:member'].map(toReactAdmin)
                    ).then(
                        data => ({ data, total: response.json['hydra:totalItems'] })
                    )
                );
            }
            case GET_LIST: { //console.log(type);      
                //FILTRO GENERAL
                let filterGet = '';
                switch (resource) {
                    case 'licencia_clientes':
                        filterGet = ""
                        break;   
                }

                // FIN FILTRO GENERAL
                url = new URL(`${entrypoint}/${resource}${filterGet}`, entrypointUrl);
                const { sort: { field, order }, pagination: { page, perPage } } = params;
                if (order) url.searchParams.set(`order[${field}]`, order);
                if (page) url.searchParams.set('page', page);
                if (perPage) url.searchParams.set('perPage', perPage);
                loadFilterParams(url, params);


                if (params.target) {
                    url.searchParams.set(params.target, params.id);
                }
                // SETEAR BUSQUEDA MEDIANTE VALORES EN ENTIDADES ANIDADAS

                

             
                
                // FIN CORRECCION DE ORDENAMIENTO MEDIANTE PROPIEDADES DE ENTIDADES ANIDADAS
                //console.log("("+type+", "+resource+", "+params+") :: "+url);
                let fetch = httpClient(url, options).then(
                    response => Promise.resolve(
                        response.json['hydra:member'].map(toReactAdmin)
                    ).then(
                        data => ({ data, total: response.json['hydra:totalItems'] })
                    )
                );
                //console.log(fetch);
                return fetch;
            }
            case CREATE: {//console.log(type);
                //console.log(JSON.stringify(params.data));
                url = new URL(`${entrypoint}/${resource}`, entrypointUrl);
                options = {
                    method: 'POST',
                    body: resource.encodeData ? resource.encodeData(params.data) : JSON.stringify(params.data)
                };

                // console.log("("+type+", "+resource+", "+params+") :: "+url);
                return httpClient(url, options).then(
                    response => Promise.resolve(
                        toReactAdmin(response.json)
                    ).then(
                        data => ({ data })
                    )
                );
            }
            case GET_ONE: {  //console.log(type);                                     
                let id = isObject(params) ? params.id : params;
                if (id !== undefined) {
                    if (isObject(params) && params.fromCache && reactAdminDocumentsCache[id]) {
                        return Promise.resolve({ data: reactAdminDocumentsCache[id] });
                    }
                    url = new URL(id, entrypoint);
                    // console.log("("+type+", "+resource+", "+params+") :: "+url);
                    return httpClient(url, options).then(
                        response => Promise.resolve(
                            toReactAdmin(response.json)
                        ).then(
                            data => ({ data })
                        )
                    );
                }
                else {
                    return Promise.resolve({ data: { id: null } });
                }
            }

            case UPDATE: { //console.log(type);
                //console.log(JSON.stringify(params.data));
                url = new URL(params.id, entrypointUrl);
                options = {
                    method: 'PUT',
                    body: resource.encodeData ? resource.encodeData(params.data) : JSON.stringify(params.data)
                };

                // console.log("("+type+", "+resource+", "+params+") :: "+url);
                return httpClient(url, options).then(
                    response => Promise.resolve(
                        toReactAdmin(response.json)
                    ).then(
                        data => ({ data })
                    )
                );

            }

            case UPDATE_MANY: {//console.log(type);
                // console.log("("+type+", "+resource+", "+params+")");
                return Promise.all(
                    params.ids.map((value) => {
                        //console.log("("+type+", "+resource+", "+params+" "+value+") :: "+url);
                        url = new URL(value, entrypointUrl);
                        options = {
                            method: 'PUT',
                            body: resource.encodeData ? resource.encodeData(params.data) : JSON.stringify(params.data)
                        };
                        return httpClient(url, options).then(
                            response => Promise.resolve(
                                toReactAdmin(response.json)
                            ).then(
                                data => ({ data })
                            )
                        );
                    }
                    )
                )
                    .then(
                        responses => ({ data: [] })
                    );
            }
            case DELETE: { //console.log(type);
                url = new URL(params.id, entrypointUrl);
                options = { method: 'DELETE', };
                // console.log("("+type+", "+resource+", "+params+") :: "+url);
                return httpClient(url, options).then(
                    response => Promise.resolve(
                        { data: { id: null } }
                    )
                );
            }
            case DELETE_MANY: {//console.log(type);
                // console.log("("+type+", "+resource+", "+params+")");
                return Promise.all(
                    params.ids.map(
                        id => fetchApi(DELETE, resource, { id })
                    )
                )
                    .then(
                        responses => ({ data: [] })
                    );
            }
            default: {
                throw new Error(`Unsupported fetch action type ${type}`);
            }
        }
        return Promise.resolve({ options: options, url: url, });
    };

    return fetchApi;
}
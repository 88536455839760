import React from 'react';
import { TextInput, required, minLength, maxLength } from 'react-admin';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueUF extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            oldValue : null,
            reference : "atributos",
            id : this.props.source
        }
        
        this.unique  = this.unique.bind(this);
    }
    componentWillMount() {
        fetch(urlAppjs + "/"+this.state.reference+".json?pagination=false",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
    }
    
    
    unique() {
        const { translate } = this.props;
        if (document.getElementById(this.props.id)) {
            var texto = document.getElementById(this.props.id).value;
            if (this.state.oldValue == null) {
                this.state.oldValue = texto;
            }
            var find = this.state.fetch.find(data => data[this.props.source].toLowerCase().trim() == texto.toLowerCase().trim());
            switch (this.props.operation) {
                case "edit":
                    if (typeof find === 'object' && find[this.props.source].toLowerCase().trim() != this.state.oldValue.toLowerCase().trim()) {
                        this.state.error = true;
                    } else {
                        this.state.error = false;
                    }
                break;
                
                default:
                    if (typeof find === 'object') {
                        this.state.error = true;
                    } else {
                        this.state.error = false;
                    }
                    break;
            }
                
        }
        if (this.state.error == true) {
            return translate("resources.validaciones.datoDigitado"); 
        }
    }
   
         
    render() {

        return (
            <TextInput
                id={this.props.id}
                label={this.props.label}
                source={this.props.source}
                fullWidth
                onChange={this.unique}
                validate={[
                    required(), 
                    this.unique
                ]}
                formClassName={this.props.formClassName}
            />
        )
    }
}

export default translate(UniqueUF);
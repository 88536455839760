import React from 'react';
import {SaveButton, TabbedForm as ReactAdminTab, Toolbar } from 'react-admin';
import BackButton from './../BotonCancelar'
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};


const TabbedFormActions = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton/>
        <BackButton />
    </Toolbar>
));
export const TabbedForm = ReactAdminTab;

TabbedForm.defaultProps = {
   toolbar: <TabbedFormActions />    
};

export default TabbedForm;
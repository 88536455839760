import React, {useState, useEffect} from 'react';
import { FormTab,required,TextInput, DateInput, NumberInput, FileField, FileInput, TabbedForm } from 'react-admin';
import { PimsCreate } from '../Create';
import Grid from '@material-ui/core/Grid';
import  {styles}  from '../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { validarConcordanciaFechas} from '../../helpers/validacionesInputs';
import config from '../../config/config'; 
let urlAppjs = config.UrlServer;

export const LicenciaClienteCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const [nombrearchivo, setNombreArchivo] = useState('');
    const ValidacionArchivo=(value)=>{
        console.log(value)
        if(value){
            var fileExt = value.title.split('.').pop();
            const data = new FormData();
            var today = new Date();
            var horaact = today.getHours();
            var minact = today.getMinutes();
            if (horaact.toString().length <= 1) {
                horaact = "0" + horaact;
            }
        
            if (minact.toString().length <= 1) {
                minact = "0" + minact;
            }
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+"-" + minact;
            let nombrearchivo= "Licenciamiento/"+date+" - licencia_cliente."+fileExt;
            setNombreArchivo(nombrearchivo);
            data.append('Licenciamiento', value.rawFile, nombrearchivo);
            fetch(urlAppjs +'/guardarArchivoAdjunto/', {
                method: 'POST',
                body: data
            })
            .then(res=> res.json())
            .then(datos => {
                //console.log(datos)
            })
        }
    }
    const ValidacionFecha=()=>{
        let fechaInicio = document.getElementById("fechaActivacion").value;
        let fechaFin = document.getElementById("fechaFinalizacion").value;
        let fechaI = new Date(fechaInicio+"T00:00:00");
        let fechaF = new Date(fechaFin+"T00:00:00");
        if(fechaI > fechaF){
            return "La fecha de activacion no puede ser mayor, a la fecha de finalización";
        }
    }
    //genero id idenficador
    const ValidacionCodigo=(value, props)=>{
        const banco = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let aleatoria = "";
        for (let i = 0; i < 15; i++) {
            aleatoria += banco.charAt(Math.floor(Math.random() * banco.length));
        }
        props.id=aleatoria;
    }
    
    return(
    <PimsCreate {...props}>
      <TabbedForm redirect="list" >
        <FormTab label="Información básica" submitOnEnter={false}>    
            <TextInput source="codigoCliente" label="Código del cliente" fullWidth validate={[required(), ValidacionCodigo]} formClassName={classes.grid_cont4s}/>
            <TextInput source="nombreCliente" label="Nombre del cliente" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
            <TextInput source="id" label="Identificador" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} style={{display:'none'}}/>            
            <Grid container spacing={24}>  
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3" className={classes.coord}>Información de licencia</Typography>                    
                    <Divider fullWidth style={{marginTop:'10px'}}/>  
                </Grid>
            </Grid>
                <DateInput source="fechaActivacion"  label="Fecha de activación" fullWidth validate={[required(), ValidacionFecha]} formClassName={classes.grid_cont4s}/>
                <DateInput source="fechaFinalizacion" label="fecha de finalización"  fullWidth validate={[required(), ValidacionFecha]} formClassName={classes.grid_cont4}/>    
            <Grid container spacing={24}>  
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3" className={classes.coord}>Datos administrativos</Typography>                    
                    <Divider fullWidth style={{marginTop:'10px'}}/>  
                </Grid>
            </Grid>  
                <DateInput source="fechaPago"  label="Fecha de pago" fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/> 
                <TextInput source="quienAutoriza" label="Quién autoriza en contabilidad" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                <TextInput source="contrato" label="No. De contrato" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                <Divider fullWidth style={{marginTop:'10px'}}/>  
                <FileInput source="archivoCarga" label="Archivo" multiple={false} validate={[required(), ValidacionArchivo]}>
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="nombreArchivo" defaultValue={nombrearchivo} fullWidth validate={[required()]} style={{display:'none'}} />
            </FormTab>  
        </TabbedForm>  
    </PimsCreate>
    )
}));
import React from 'react';
import Button from '@material-ui/core/Button';
import { TabbedShowLayout, Tab, TextField, DateField, SelectField } from 'react-admin';
import { Show } from '../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import  {styles}  from '../../EstilosGrid';
import config from '../../config/config';
let urlAppjs = config.UrlServer;
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

export const LicenciaClienteShow =withStyles(styles)(({ classes, ...props }) => {
    const UrlField = ({ record, source }) =>
        <a href={urlAppjs + "/" + record[source]} target="_blank" style={{ textDecoration: "none" }}>
            <Button variant="outlined" color="primary">Descargar</Button>
        </a>;

    return(
    <Show  {...props} title="Información del cliente" bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="Información básica" submitOnEnter={false} >
                <TextField source="codigoCliente" label="Código del cliente" fullWidth formClassName={classes.grid_cont4s}/> 
                <TextField source="nombreCliente" label="Nombre del cliente" fullWidth formClassName={classes.grid_cont4}/>
                <DateField source="fechaCreacion" label="Fecha de creación"  showTime />
                
                <Typography variant="h3" component="h3" style={{ width: '300%', marginTop:'16%' }}>
                    Información de licencia
                </Typography>
    
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <DateField source="fechaActivacion"  label="Fecha de activación" fullWidth formClassName={classes.grid_cont4s}/>
                <DateField source="fechaFinalizacion" label="Fecha de finalización" fullWidth formClassName={classes.grid_cont4}/>  
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%', marginTop:'16%' }}>
                    Datos administrativos
                </Typography>

                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <DateField source="fechaPago" label="Fecha de pago" fullWidth formClassName={classes.grid_cont4}/>
                <TextField source="quienAutoriza" label="Quién autoriza en contabilidad" fullWidth formClassName={classes.grid_cont4s}/> 
                <TextField source="contrato" label="No. de contrato" fullWidth formClassName={classes.grid_cont4}/>
                <Divider />
                <Divider />
                <Divider />
                <UrlField label="Desscargar archivo" source="nombreArchivo" />
            </Tab>
            

            <Tab label="Auditoría">
                <DateField label="Creado en" source="createdAt" showTime />
                <TextField label="Creado por" source="createdBy"  />
                <div></div>
                <DateField label="Actualizado en" source="updatedAt" showTime />
                <TextField label="Actualizado por" source="updatedBy"  />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
    });
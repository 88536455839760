import React, {useState, useEffect} from 'react';
import { FormTab,NumberInput, FormDataConsumer, TextInput,required,minLength,Toolbar, SaveButton, ReferenceInput, SelectInput , Datagrid, TextField, CardActions, Query, AutocompleteInput, CreateButton, SimpleForm
} from 'react-admin';
import { Edit } from './../Edit';
import { TabbedForm } from './../TabbedForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import LocaleSwitcher from './traduccion/LocaleSwitcher';
import config from '../../config/config';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import BackButton from '../../BotonCancelar';
// import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';

let urlAppjs = config.UrlServer;


export const UsuarioEdit =  translate(withStyles(styles)(({ record, classes, ...props }) => {
    const { translate } = props;
    const[rol, setRol] = useState('');
    const ValidarAdminRol = (value) =>{
        setRol(value)
    }  
    const validarEmail=(elemento)=>{

        var texto = document.getElementById("emails").value;
        var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        
        if (!regex.test(texto)) {
            return translate('resources.validaciones.correoInvalido');
        } else {
            console.log("Correo valido") ;
        }
    
    }
    const validarNombre=(elemento)=>{
        var texto = document.getElementById("nombres").value;
        var regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
        
        if (!regex.test(texto)) {
            return translate('resources.validaciones.valNombre');
        } else {
            console.log("nombre valido") ;
        }
    }
    const validarApellido=(elemento)=>{
        var texto = document.getElementById("apellidos").value;
        var regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
        
        if (!regex.test(texto)) {
            return translate('resources.validaciones.valApellido');
        } else {
            console.log("apellido valido") ;
        }
    }
    const toolbarStyles = {
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
    const[valClave1, setValClave1] = useState("");
    const[valClave2, setValClave2] = useState("");

    const contrasena =()=>{
        let clave1 = document.getElementById("clave1").value;
        let clave2 = document.getElementById("clave2").value;
        
        setValClave1(clave1);
        setValClave2(clave2);
       
        if((clave2 !== null && clave2 !== undefined  && clave2 !== '') && (clave1 !== null && clave1 !== undefined  && clave1 !== '')){
            if (clave1 != clave2) {
                return translate('resources.validaciones.contraseniaNoCoindice');
            }
        }else if((clave2 == null || clave2 == undefined || clave2 == '') && (clave1 !== null && clave1 !== undefined  && clave1 !== '')){
            if (clave1 != clave2) {
                return translate('resources.validaciones.contraseniaNoCoindice');
            }
        }else if((clave1 == null || clave1 == undefined || clave1 == '') && (clave2 !== null && clave2 !== undefined  && clave2 !== '')){
            if (clave1 != clave2) {
                return translate('resources.validaciones.contraseniaNoCoindice');
            }
        }
    }
    const PimsToolbarActions = translate(withStyles(toolbarStyles)(props => {
        if(valClave1 != "" && valClave2 !=""){
            props.record.plainPassword = valClave1;     
        }else{
            props.record.plainPassword = "";
        }
        return (
            <Toolbar {...props}>
                <SaveButton />
                <BackButton />
            </Toolbar>
        )
    }));
  
return(
<Edit title="dynatest.resources.usuarios.title.editTitle" {...props}  undoable={false}>
    <SimpleForm submitOnEnter={false} redirect="list" toolbar={<PimsToolbarActions />} autocomplete="off">
        <FormTab label="dynatest.resources.usuarios.formTabs.identificacionTitle">
        {
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <TextInput label="resources.usuarios.fields.nombres" source="nombres" id="nombres" fullWidth validate={[required(), minLength(3), validarNombre]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.usuarios.fields.apellidos" source="apellidos" id="apellidos" fullWidth validate={[required(), minLength(3), validarApellido]}/>
                </Grid>
                <Grid item xs={6} style={{display:'none'}}>
                    <SelectInput label="resources.usuarios.fields.tipo_documento" source="tipo_documento" choices={[
                        { id: translate('dynatest.resources.usuarios.select.documento.cedula_ciudadania'), name: 'dynatest.resources.usuarios.select.documento.cedula_ciudadania' },
                        { id: translate('dynatest.resources.usuarios.select.documento.cedula_extrangeria'), name: 'dynatest.resources.usuarios.select.documento.cedula_extrangeria' },
                        { id: translate('dynatest.resources.usuarios.select.documento.nit'), name: 'dynatest.resources.usuarios.select.documento.nit' },
                    ]} fullWidth defaultValue={translate('dynatest.resources.usuarios.select.documento.cedula_ciudadania')} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="resources.usuarios.fields.identificacion" source="identificacion" fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <ReferenceInput 
                            label="resources.usuarios.fields.admin" 
                            source="admin"  
                            reference="atributos" 
                            filterToQuery={searchText => ({ nombre: searchText })} 
                            sort={{field : 'nombre', order : 'asc'}}
                            alwaysOn
                            fullWidth 
                            validate={[required(), ValidarAdminRol]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  id='adminRol'/>
                        </ReferenceInput>  
                </Grid>
                {                                    
                    <Query type="GET_ONE" resource="atributos" payload={{ id: rol}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  style={{display:'none'}} label="NombreRol" defaultValue={data.nombre} source="adminRol" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                {/* <TextInput type='password' label="bd" source="password" id="PasswordValidate2"  fullWidth/>  */}
                <Grid item xs={8}>
                    <TextInput label="dynatest.uniqueEmail.email" source="username" id="emails" fullWidth validate={[validarEmail, required()]}/>
                </Grid>

                <Grid item xs={6}>
                    <TextInput type='password' autoComplete="new-password" label="resources.usuarios.fields.password" source="passwordValidate" defaultValue="" id="clave1" fullWidth validate={[contrasena]}/>
                </Grid>
                <Grid item xs={6}>{}
                    <TextInput type='password' autoComplete="new-password" label="resources.usuarios.fields.plainPassword" source="plainPassword" id="clave2" defaultValue="" fullWidth validate={[contrasena]}/> 
                    {/* <TextInput type='password' source="plainPassword" id="clave2" fullWidth validate={[contrasena]} defaultValue=""/>               */}
                </Grid>
                
                <Grid item xs={6}>
                    <TextInput source="idioma" defaultValue="es" fullWidth validate={[required()]} style={{display:'none'}} />
                </Grid>
                {/* <LocaleSwitcher /> */}
            </Grid>
         }
        </FormTab>
    </SimpleForm>
       
    </Edit>
)
}));
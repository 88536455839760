import React from 'react';
import {
    TabbedShowLayout, Tab, TextField, DateField, ReferenceField, NumberField, SelectField
} from 'react-admin';
import { Show } from './../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import Divider from '@material-ui/core/Divider';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

/* const opciones = [
    { id: 0, name: 'No Definido' },
    { id: null, name: 'No Definido' },
    { id: 1, name: translate('dynatest.resources.atributos.select.acceso.acceso_completo') },
    { id: 2, name: 'Acceso Parcial' },
    { id: 3, name: 'Sin Acceso' },
    { id: 4, name: 'Visualiza' }
]; */

export const UsuarioShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const opciones = [
        { id: 0, name: translate('dynatest.resources.atributos.select.acceso.noDefinido') },
        { id: null, name: translate('dynatest.resources.atributos.select.acceso.noDefinido') },
        { id: 1, name: translate('dynatest.resources.atributos.select.acceso.acceso_completo') },
        { id: 2, name: translate('dynatest.resources.atributos.select.acceso.acceso_parcial') },
        { id: 3, name: translate('dynatest.resources.atributos.select.acceso.sin_acceso') },
        { id: 4, name: translate('dynatest.resources.atributos.select.acceso.visualiza')  }
    ];
    return (
    <Show title="dynatest.resources.usuarios.title.showTitle"  {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.usuarios.tabs.informacionUsuario">
                <TextField source="nombres" />
                <TextField source="apellidos" />
                <div></div>
                {/* <TextField label="resources.usuarios.fields.tipo_documento" source="tipo_documento" /> */}
                <NumberField source="identificacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} />
                <ReferenceField source="admin" reference="atributos" linkType={false} >
                    <TextField source="adminNombre" />
                </ReferenceField>
                <div></div>
                <TextField label="dynatest.uniqueEmail.email" style={{ width: '300%' }} source="username" />
                <div></div><div></div><div></div>
                <Typography className=" Probando " variant="h3" component="h3" style={{ color: '#4d4d91', textAlign: 'center', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.acceso_usuario_app')}
                </Typography>
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.administracion')}
                </Typography>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.tipo_rol" source="admin" reference="atributos" linkType={false} >
                    <TextField source="tipoRol" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.usuarios" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="usuario" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.perfiles" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="perfiles" />
                </ReferenceField>
                
                
                <div></div>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>Clientes</Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="Clientes" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="licencias" />
                </ReferenceField>
                
                <div></div>
            </Tab>
            <Tab label="dynatest.resources.usuarios.tabs.auditoria">
                <DateField source="createdAt" showTime/>
                <TextField source="createdBy" />
                <div></div>
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy" />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
    )
}));
import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,DateField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const LicenciaClienteList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
            actions={permissions['licencias']  == 1 ? <ListAction /> : permissions['licencias']  == 2 ? <ListAction2 />: null} 
            sort={{ field: 'id', order: 'DESC' }} 
            bulkActionButtons={permissions['licencias']  == 1 ? <PostBulkActionButtons />: null} >
            <Datagrid >
                <TextField fullWidth label="Código cliente" source="codigoCliente" />
                <TextField fullWidth label="Nombre de cliente" source="nombreCliente"/>
                <DateField fullWidth label="Fecha de inicio de activación" source="fechaActivacion" />
                <DateField fullWidth label="Fecha de finalización de activación" source="fechaFinalizacion" />
                {permissions['licencias'] == 1 || permissions['licencias'] == 2 || permissions['licencias'] == 4 ? <ShowButton/>: null}
                {permissions['licencias'] == 1 || permissions['licencias'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { images } from './images';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
// import logo from './logo-login.png';
import logo from './Logo-Dynatest.png';
import loader from './Ring-Preloader.gif';

class MyLoginPage extends Component {

    INITIAL_INDEX = 0;

    state = {
        index: this.INITIAL_INDEX
    };

    setIndex(value) {
        if (value === images.length) {
            this.setState({ index: this.INITIAL_INDEX });
        } else {
            this.setState({ index: value });
        }
    }

    submit = (e) => {
        e.preventDefault();

        const User = document.getElementById('userLogin');
        const Pass = document.getElementById('PassLogin');
        const credentials = {
            username: User.value,
            password: Pass.value
        }

        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
    }

    closeMsj = (e) => {
        const content = document.getElementById('content-msj');
        content.style.display = 'none';
    }

    interval = setInterval(() => {
        if (this.state.index === images.length - 1) {
            this.setState({ index: this.INITIAL_INDEX });
        } else {
            this.setState({ index: this.state.index + 1 });
        }

    }, 15000)

    render() {

        const { index } = this.state;

        return (
            <MuiThemeProvider theme={this.props.theme}>
                <div className="container-login">
                    {/* CARRUSEL */}
                    <div id='gallery-responsive'>
                        <Gallery
                            index={index}
                            
                            onRequestChange={i => {
                                this.setIndex(i);
                            }}
                        >
                            {images.map(image => (
                                <GalleryImage objectFit="fit" src={image} />
                            ))}
                        </Gallery>
                    </div>
                    {/* FORMULARIO */}
                    <div id='form-login-responsive'>
                        <div>
                            <div className="tittle-login">
                                <div>
                                    <span style={{ fontSize: '40px' }}>Bienvenido a <strong style={{ color: 'black ' }}>IRIS</strong></span>
                                </div>
                                <div>
                                    <span style={{ fontSize: '20px' }}>Integrated Road Infrastructure System</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <a href="https://www.dynatest.com/evaluacion-consultoria-pavimentos" target="_blank">
                                    <img className="logo-login" src={logo} />
                                </a>
                            </div>
                            <br></br>
                            <div>
                                <form onSubmit={this.submit}>
                                    <div className="input-group">
                                        <label style={{ fontSize: '20px' }}>Usuario</label><br />
                                        <input type="text" className="user" id="userLogin" placeholder="Usuario" />
                                    </div>
                                    <div className="input-group">
                                        <label style={{ fontSize: '20px' }}>Contraseña</label><br />
                                        <input type="password" className="pass" id="PassLogin" placeholder="Contraseña" />
                                    </div>
                                    <div className="input-group">
                                        <button type="submit" id="btn-login">Iniciar sesión</button>
                                        <img src={loader} id="loader-btn-login" />
                                    </div>
                                </form>
                            </div>
                            <div className="footer-login">
                            </div>
                        </div>
                        <img id='imgLoginFooter' src="/imagenes/loginPpal/footer.png" />
                    </div>
                    {localStorage.getItem('msj-login-error') && (
                        <div id="content-msj">
                            <div class="content-msj-error-login">
                                <div>

                                </div>
                                <div id='modal-error-login'>
                                    <p><strong id="msj-error-text">{localStorage.getItem('msj-login-error-msj')}</strong></p>
                                    <hr />
                                    <button onClick={this.closeMsj} id="btn-close-msj-error">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </MuiThemeProvider>
        );
    }
};

export default connect(undefined, { userLogin })(MyLoginPage);
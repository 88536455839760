import React from 'react';
import { Edit as ReactAdminEdit, CardActions, ListButton, ShowButton } from 'react-admin';

const EditActions = ({
    basePath,
    className,
    data,
    hasList,
    hasShow,
    resource,
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </CardActions>
);

export const Edit = ReactAdminEdit;

Edit.defaultProps = {
   actions: <EditActions />    
};

export default Edit;
import React from 'react';
import { Admin, Resource, Error } from 'react-admin';
import { render } from 'react-dom';
import NotFound from './NotFound';
/**
 * Hydra
 */
import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import hydraClient from './hydra/hydraClient';
// IMAGEN
import addUploadFeature from './addUploadFeature';
/**
 * Temporales
 */
import { EditGuesser, ShowGuesser } from 'react-admin';
/**
 * Seguridad
 */
import PimsAuthProvider from './PimsAuthProvider';
import PimsAuthProviderPermissions from './PimsAuthProviderPermissions';

/**
 * Reducers
 */
import proyecto from './reducers/proyectoReducer';
/**
 * Layout
 */
import PimsLayout from './PimsLayout';

/**
 * Recursos
 *
 */

import { AtributosList } from './resources/atributo/list';
import { AtributosShow } from './resources/atributo/show';
import { AtributosCreate } from './resources/atributo/create';
import { AtributosEdit } from './resources/atributo/edit';

import { UsuarioList } from './resources/usuario/list';
import { UsuarioShow } from './resources/usuario/show';
import { UsuarioCreate } from './resources/usuario/create';
import { UsuarioEdit } from './resources/usuario/edit';

import { LicenciaClienteList } from './resources/licencia_cliente/list';
import { LicenciaClienteShow } from './resources/licencia_cliente/show';
import { LicenciaClienteCreate } from './resources/licencia_cliente/create';
import { LicenciaClienteEdit } from './resources/licencia_cliente/edit';



/**
 * Sagas
 */
import errorSaga from './sagas/errorSaga';

/* LOGIN PAGE*/
import LoginPage from './LoginPage';
import config from './config/config';

/**
 * Idioma General
 */
import domainTranslationsEs from './languages/dynatest-language-spanish';

let urlAppjs = config.UrlServer;
const entrypoint = urlAppjs;//process.env.apiURL


// const headers = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, { ...options, headers: new Headers({ 'Authorization': `Bearer ${window.localStorage.getItem('token')}` }), });

const dataProvider = hydraClient(entrypoint, fetchHydra);

//IMAGEN
const uploadCapableDataProvider = addUploadFeature(dataProvider);
const authProvider = new PimsAuthProvider({ url: entrypoint });


document.addEventListener("mousewheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

document.addEventListener("keydown", function (event2) {
  if (document.activeElement.type === "number") {
    if (event2.keyCode === 38 || event2.keyCode === 40) {
      event2.preventDefault();
    }
  }
})


const messages = {
  'es': domainTranslationsEs,
};
const i18nProvider = locale => messages[locale];

const userSelect =  () => {
  var idiomaUsuario = 'es';
  return idiomaUsuario;
}

const App = () =>
  <Admin

    // dataProvider={dataProvider}

    dataProvider={uploadCapableDataProvider}
    authProvider={authProvider.handleEvent}
    // authProvider={authProvider}
    // authProvider={PimsAuthProviderPermissions}
    appLayout={PimsLayout}
    
    i18nProvider={i18nProvider}
    locale={userSelect()}
    customReducers={{ proyecto }}
    customSagas={[errorSaga]}
    loginPage={LoginPage}
  >
    {permissions => [
      <Resource name="licencia_clientes" list={permissions['licencias'] != 3 ? LicenciaClienteList : NotFound} edit={permissions['licencias'] == 1 || permissions['licencias'] == 2 ? LicenciaClienteEdit : null} show={LicenciaClienteShow} create={permissions['licencias'] != 4 ? LicenciaClienteCreate : null} />,
      <Resource name="atributos" list={permissions['perfiles'] != 3 ? AtributosList : NotFound} edit={permissions['perfiles'] == 1 || permissions['perfiles'] == 2 ? AtributosEdit : null} show={AtributosShow} create={permissions['perfiles'] != 4 ? AtributosCreate : null} />,
      <Resource name="usuarios" list={permissions['usuario'] != 3 ? UsuarioList : NotFound} edit={permissions['usuario'] == 1 || permissions['usuario'] == 2 ? UsuarioEdit : null} show={UsuarioShow} create={permissions['usuario'] != 4 ? UsuarioCreate : null} />, 
    ]}
  </Admin>
export default App;

export const images = [
  "/imagenes/loginPpal/img_login1.jpg",
  "/imagenes/loginPpal/img_login2.jpg",
  "/imagenes/loginPpal/img_login3.jpg",
  "/imagenes/loginPpal/img_login4.jpg",
  "/imagenes/loginPpal/img_login5.jpg"
];

/**"https://images.pexels.com/photos/2026324/pexels-photo-2026324.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "https://images.pexels.com/photos/967072/pexels-photo-967072.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",    
    "https://images.pexels.com/photos/912050/pexels-photo-912050.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "https://images.pexels.com/photos/1797295/pexels-photo-1797295.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "https://images.pexels.com/photos/1646164/pexels-photo-1646164.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
     */
// https://images.pexels.com/photos/934070/pexels-photo-934070.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260
// https://images.pexels.com/photos/2129970/pexels-photo-2129970.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940
// https://images.pexels.com/photos/2237801/pexels-photo-2237801.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940
// https://images.pexels.com/photos/34577/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940




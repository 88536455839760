import React from 'react';
import { Show as ReactAdminShow, CardActions, ListButton, EditButton } from 'react-admin';

const ShowActions = ({
    basePath,
    className,
    data,
    hasList,    
    hasEdit,
    resource,
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}
        {hasEdit && <EditButton basePath={basePath} record={data} />}
    </CardActions>
);

export const Show = ReactAdminShow;

Show.defaultProps = {
   actions: <ShowActions />    
};

export default Show;
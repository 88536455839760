export default {
    ra: {
      action: {
          delete: 'Borrar',
          show: 'Mostrar',
          list: 'Listar',
          save: 'Guardar',
          create: 'Crear',
          edit: 'Editar',
          sort: 'Ordenar',
          cancel: 'Cancelar',
          undo: 'Deshacer',
          refresh: 'Refrescar',
          add: 'Agregar',
          remove: 'Remover',
          add_filter: 'Agregar filtro',
          remove_filter: 'Remover este filtro',
          back: 'Regresar',
          bulk_actions: '%{smart_count} seleccionado',
          confirm:'Confirmar',
          save_add: 'Guardar y agregar',
          save_end: 'Guardar y finalizar',
          clear_input_value: 'Limpiar valor',
          clone: 'Clonar',
          create_item: 'Crear %{item}',
          export: 'Exportar',
          search: 'Buscar',
          unselect: 'Deseleccionar',
          expand: 'Expandir',
          close: 'Cerrar',
          open_menu: 'Abrir menú',
          close_menu: 'Cerrar menú',
          update: 'Actualizar',
          image: 'Imagen',
          carouselImage: 'GALERÍA',
          imageActuales: 'Imágenes actuales',
          imageEliminar: 'ELIMINAR',
        },
        boolean: {
          true: 'Si',
          false: 'No',
          null: ' ',
        },
        page: {
          list: 'Lista de %{name}',
          edit: '%{name} #%{id}',
          show: '%{name} #%{id}',
          create: 'Crear %{name}',
          dashboard: 'Dashboard',
          not_found: 'No encontrado',
          loading: 'Cargando',
          invite: '¿Quieres añadir uno?',
        },
        input: {
          file: {
            upload_several:
                            'Arrastra algunos archivos para empezar la subida, o presiona aquí para seleccionarlos.',
            upload_single: 'Arrastra un archivo para empezar la subida, o presiona aquí para seleccionarlo.',
          },
          image: {
            upload_several:
                            'Arrastra algunas imágenes para empezar la subida, o presiona aquí para seleccionar una.',
            upload_single:
                          'Arrastra una imagen aquí para empezar la subida, o presiona aqui para seleccionarla.',
          },
          references: {
            all_missing: 'No se encontró ninguna referencia.',
            many_missing:
                          'Al menos una de las referencias asociadas parece ya no estar disponible.',
            single_missing:
                            'La referencia asociada parece ya no estar disponible.',
          },
          password: {
            toggle_visible: 'Ocultar contraseña',
            toggle_hidden: 'Mostrar contraseña',
        },
        },
        message: {
          yes: 'Si',
          no: 'No',
          are_you_sure: '¿Estás seguro?',
          about: 'Acerca de',
          not_found:
                    'Ha escrito una URL incorrecta o ha seguido un enlace incorrecto.',
          loading: 'La página esta cargando, espera un momento por favor',
          invalid_form: 'El formulario no es válido. Por favor busca por errores',
          delete_title: 'Borrar %{name} #%{id}',
          delete_content: '¿Estás seguro que quieres borrar este elemento?',
          bulk_delete_title:
                            'Borrar %{name} |||| Borrar %{smart_count} %{name} elementos',
          bulk_delete_content:
                              '¿Estás seguro de que quieres borrar este %{name}? |||| ¿Estás seguro de que quieres borrar estos %{smart_count} elementos?',
        },
        navigation: {
          no_results: 'No se encontraron resultados',
          no_more_results:
                          'La página número %{page} esta fuera de los límites. Prueba con la página anterior.',
          page_out_of_boundaries: 'La página número %{page} se encuentra fuera de los límites',
          page_out_from_end: 'No se puede navegar después de la última página',
          page_out_from_begin: 'No se puede navegar antes de la página 1',
          page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
          page_rows_per_page: 'Filas por página:',
          next: 'Siguiente',
          prev: 'Anterior',
        },
        auth: {
          username: 'Usuario',
          password: 'Contraseña',
          sign_in: 'Loguearse',
          sign_in_error: 'Error de autenticación, por favor reintenta',
          logout: 'Cerrar sesión',
        },
        notification: {
          updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados',
          created: 'Elemento creado',
          deleted: 'Elemento borrado |||| %{smart_count} elementos borrados',
          bad_item: 'Elemento incorrecto',
          item_doesnt_exist: 'El elemento no existe',
          http_error: 'Error de comunicación con el servidor',
          canceled: 'Acción cancelada',
        },
        validation: {
          required: 'Requerido',
          minLength: 'Debe tener al menos %{min} carácteres',
          maxLength: 'Debe tener %{max} carácteres o menos',
          minValue: 'Debe ser al menos %{min}',
          maxValue: 'Debe ser %{max} o menos',
          number: 'Debe ser un número',
          email: 'Debe ser un correo electrónico válido',
          oneOf: 'Debe ser uno de los siguientes valores: %{options}',
          regex: 'Debe seguir un formato específico (regexp): %{pattern}',
        },
    },
    resources: {
      inicio:{
        titleProyecto:'Selección de proyectos',
        mensaje:'La página esta cargando, espere un momento por favor',
        seleccionar:'Seleccionar',
      },
      usuarios: {
          name: 'Usuario |||| Usuarios',
          fields: {
              nombres: 'Nombres',
              apellidos: 'Apellidos',
              tipo_documento: 'Tipo de documento',
              identificacion: 'Número de identificación',
              admin: 'Rol',
              password: 'Contraseña',
              plainPassword: 'Confirmar contraseña',
              idioma: 'Idioma',
              createdAt: 'Creado el',
              createdBy: 'Creado por',
              updatedAt: 'Actualizado el',
              updatedBy: 'Actualizado por',
              sections:{
                tipo_rol: 'Tipo de rol',
                usuarios: 'Usuarios',
                perfiles: 'Perfiles',
                asignar_clave: 'Asignar Clave',
                proyectos: 'Proyectos',
                rutas: 'Rutas',
                unidades_funcionales: 'Unidades funcionales',
                tramos: 'Tramos',
                segmentos: 'Segmentos',
                materiales: 'Materiales',
                granulometria: 'Granulometría',
                parametros_evaluacion: 'Parámetros de Evaluación',
                diagrama_colorimetrico: 'Diagrama Colorimétrico',
                reparaciones: 'Reparaciones',
                deterioros: 'Deterioros',
                contacto_emergencia: 'Contacto de Emergencia',
                proveedores: 'Provedores',
                servicios_publicos: 'Servicios Públicos',
                mapa: 'Mapa',
                flexible: 'Flexible',
                rigido: 'Rígido',
                adoquin: 'Adoquín',
                otro: 'Otro',
                sistemas_contencion: 'Sistema de Contención',
                drenaje: 'Drenaje',
                señalizacion:  'Señalización',
                alrededores:  'Alrededores',
                otros_elementos: 'Otros Elementos',
                redes: 'Redes',
                puentes: 'Puentes',
                contencion: 'Contención',
                ponton: 'Pontón',
                tuneles: 'Túneles',
                inventario_peajes: 'Inventario Peajes',
                transito_historico: 'Tránsito Histórico',
                inv_est_conteo: 'Inventario Estación de conteo',
                indicadores_4g: 'Indicadores 4G',
                datos_basicos: 'Datos básicos',
                deterioro: 'Deterioro',
                calificacion_puentes: 'Calificación de puentes',
                calificacion_pontones: 'Calificación de pontones',
                disp_vias: 'Disponibilidad de Vías',
                cola_peaje: 'Cola de peaje',
                parametros_planeacion: 'Parámetros de planeación',
                politica_general: 'Políticas generales',
                politica_particular: 'Políticas particulares',
                alternativa_intervencion: 'Alternativa de Intervención',
                repa_menores: 'Reparaciones Menores',
                exportar_pers: 'Exportar base pers',
                reportes_pavimentos: 'Importar base PERS',
                flujo_caja: 'Flujo de caja',
                idioma: 'Idioma',
                sistema: 'Sistema',
                indicadorComplementario: 'Indicador complementario',
                novedades: 'Novedades',
                cargaMasiva: 'Carga masiva',
                complementarios: 'Complementarios',
                costo: 'Registro de Costos',
                respuestaEvaluacionNovedad: 'Novedades',
                aprobacion: 'Aprobación'
              }
          }
      },
      atributos:{
        name: 'Atributo |||| Atributos',
        fields: {
          nombre: 'Roles',
          tipoRol: 'Tipo de rol',
          usuario: 'Usuarios',
          perfiles: 'Perfiles',
          asigclaves: 'Asignar clave',
          proyecto: 'Proyectos',
          rutas: 'Rutas',
          unifuncionales: 'Unidades Funcionales',
          tramos: 'Tramos',
          segmento: 'Segmentos',
          materiales: 'Materiales',
          ganulometria: 'Granulometría',
          parametrosEvaluacion: 'Parámetros de Evaluación',
          diagramaColorimetrico: 'Diagrama Colorimétrico',
          reparaciones: 'Reparaciones',
          deterioros: 'Deterioros',
          contemergencia: 'Contactos de Emergencia',
          proveedores: 'Proveedores',
          serpublicos: 'Servicios Públicos',
          mapa: 'Mapa',
          flexible: 'Flexible',
          rigido: 'Rígido',
          adoquin: 'Adoquín',
          otro: 'Otro',
          seguridad: 'Sistemas de Contención',
          drenaje: 'Drenaje',
          senalizacion: 'Señalización',
          alrededores: 'Alrededores',
          otroElemento: 'Otros Elementos',
          redes: 'Redes',
          puentes: 'Puentes',
          contencion: 'Contención',
          ponton: 'Ponton',
          tunel: 'Túneles',
          inventario: 'Inventario Peajes',
          transitoMensual: 'Tránsito Histórico',
          inventarioa: 'Inventario Estación de conteo',
          indicadores4g: 'Indicadores 4G',
          datosBasicos: 'Datos básicos',
          colaDePeaje: 'Deterioro',
          calificacionPuentes: 'Calificación Puentes',
          calificacionPontones: 'Calificación Pontones',
          disponibilidadVia: 'Disponibilidad de Vías',
          colaDePeajea: 'Cola de Peaje',
          parametrosAnalisis: 'Parámetros de Planeación',
          politicaGeneral:'Políticas Generales',
          politicaParticular:'Políticas Particulares',
          alternativaIntervencion: 'Alternativa de Intervención',
          reparacionesMenores: 'Reparaciones Menores',
          exportarPers: 'Exportar base PERS',
          reportesPers: 'Importar base PERS',
          flujoCaja: 'Flujo de Caja',
          reportesConfig: 'Configuración Reportes',
          reportesPersQ: 'Reportes PERS',
          reportesInventarioQ: 'Reportes Inventario',
          reportesIndicadoresQ: 'Reportes Indicadores',
          reportesCostosQ: 'Reportes Costos',
          reportesTransitoQ: 'Reportes Tránsito',
          reportesContactosQ: 'Otros Reportes',
          reportesModelacionQ: 'Reportes Modelación',
          createdAt: 'Creado el',
          createdBy: 'Creado por',
          updatedAt: 'Actualizado el',
          updatedBy: 'Actualizado por',
          novedades: 'Novedades',
          configSistema: 'Sistema',
          costo: 'Registro de Costos'
        }
      },     
    },
    dynatest : {
      resources: {
        imagenesInventario: {
          titleImagen: 'Tipo de elemento',
          descripcionImagen: 'Arrastra algunas imágenes para empezar la subida o presiona aquí para seleccionar una',
        },
        pimsMenu: {
            fields: {
                inicio:'Inicio',
                clientes: "Clientes",
                configuraciones: "Configuraciones",
            },
            subMenu: {
              usuarios: 'Usuarios',
              atributos: 'Atributos',
            }
        },
        
        usuarios:{
          title:{
            editTitle: 'Editar Usuario',
            showTitle: 'Información Usuario'
          },
          formTabs:{
            identificacionTitle: 'Identificación',
          },
          select:{
            documento:{
              cedula_ciudadania: 'Cédula de Ciudadanía',
              cedula_extrangeria: 'Cédula de extrangería',
              nit: 'NIT',
            }
          },
          tabs: {
            informacionUsuario: 'Información del usuario',
            informacionBasica: 'Información básica',
            auditoria : 'Auditoria',
            acceso_usuario_app: 'ACCESOS DEL USUARIO EN LA APLICACIÓN',
            administracion: 'ADMINISTRACIÓN',
            proyectosMay: 'PROYECTOS',
            proyectosMin: 'Proyectos',
            confMateriales: 'Configuraciones - Materiales',
            confIndicadores: 'Configuraciones - Indicadores',
            confDeterioro: 'Configuraciones - Deterioro PERS',
            contactos: 'Contactos',
            elem_inventario: 'ELEMENTOS INVENTARIO',
            segmentos_cal: 'Segmentos (Calzada)',
            inventario: 'Inventarios',
            puentes_estructuras: 'Puentes y estructuras especiales',
            transito: 'TRÁNSITO',
            indicadoresMay: 'INDICADORES',
            indicadoresMin: 'Indicadores',
            gestion: 'GESTIÓN',
            programacion: 'Programación',
            otros_sistemas: 'Otros Sistemas',
            pavimento_pers: 'Pavimento PERS',
            flujo_cajaTitle: 'Flujo de Caja',
            idiomas: 'Idiomas',
            configGeneral: 'Configuración General',
            configNovedades : 'Configuraciones - Novedades',
            cargaMasiva : 'Carga masiva',
            registroCostos: 'Registro de Costos',
            aplicacionMovil: 'APLICACIÓN MÓVIL'
        },
        },
        atributos:{
          formTabs:{
            administracion: 'Administración',
            proyecto: 'Proyecto',
            inventario: 'Inventario',
            transito: 'Tránsito',
            indicadores: 'Indicadores',
            gestion: 'Gestión',
            reportesQS: 'Reportes QuickSight',
            auditoria: 'Auditoría',
            aplicacionMovil: 'Aplicación Móvil'

          },
            select:{
              acceso:{
                acceso_completo: 'Acceso Completo',
                acceso_parcial: 'Acceso Parcial',
                sin_acceso: 'Sin Acceso',
                visualiza: 'Visualiza',
                noDefinido: 'No Definido'
              },
              tipo_rol:{
                administrativo: 'Administrativo',
                campo: 'Campo',
                oficina: 'Oficina',
              }
          },
          title: {
            proyectos: 'Proyectos',
            configMateriales: 'Configuraciones - Materiales',
            configIndicadores: 'Configuraciones - Indicadores',
            configDeterioroPers: 'Configuraciones - Deterioro PERS',
            contactos: 'Contactos',
            segmentos: 'Segmentos (Calzada)',
            elemInventario: 'Elementos Inventario',
            puentesEstructurasEspec: 'Puentes y Estructuras Especiales',
            programacion: 'Programación',
            otrosSistemas: 'Otros Sistemas',
            pavimentoPers: 'Pavimento PERS',
            flujoCaja: 'Flujo de Caja',
            editTitle: 'Atributos',
            showTitle: 'Atributos',
            configNovedades: 'Configuraciones - Novedades',
            complementarios: 'Complementarios',
            respuestaEvaluacionNovedad: 'Novedades',
            novedades: 'Novedades',
            aprobacion: 'Aprobación',
            configGeneral: 'Configuración General',
            registroCostos: 'Registro de Costos',
            cargaMasiva: 'Carga Masiva',
        }
        },    
      },
      uniqueEmail:{
        email: 'Correo Electrónico'
      }
  }
}


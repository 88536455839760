import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { changeLocale as changeLocaleAction } from 'react-admin';

class LocaleSwitcher extends Component {
    switchToSpanish = () => this.props.changeLocale('es');
    switchToEnglish = () => this.props.changeLocale('en');
    switchToPortuguese = () => this.props.changeLocale('pt');
    
    render() {
        const { changeLocale } = this.props;
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.switchToEnglish}>English</Button>
                <Button variant="outlined" color="primary" onClick={this.switchToSpanish}>Español</Button>
                <Button variant="outlined" color="primary" onClick={this.switchToPortuguese}>Português</Button>
            </div>
        );
    }
}

export default connect(undefined, { changeLocale: changeLocaleAction })(LocaleSwitcher);
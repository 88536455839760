import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AdminIcon from '@material-ui/icons/Domain';
import InventarioIcon from '@material-ui/icons/Streetview';
import HomeIcon from '@material-ui/icons/Home';
import Report from '@material-ui/icons/Report';
import BookIcon from '@material-ui/icons/Book';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ContactsIcon from '@material-ui/icons/Contacts';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Usuario from '@material-ui/icons/People';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import GradientIcon from '@material-ui/icons/Gradient';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import InvertColors from '@material-ui/icons/InvertColors';
import FilterHdr from "@material-ui/icons/FilterHdr";
import DescriptionIcon from '@material-ui/icons/Description';
import { withRouter } from 'react-router-dom';
import CropIcon from '@material-ui/icons/Crop';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SatelliteIcon from '@material-ui/icons/Satellite';
import StyleIcon from '@material-ui/icons/Style';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SettingsIcon from '@material-ui/icons/Settings';
import SvgIcon from '@material-ui/core/SvgIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import TimelineIcon from '@material-ui/icons/Timeline';
import PublicIcon from '@material-ui/icons/Public';
import EventIcon from '@material-ui/icons/Event';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EventNoteIcon from '@material-ui/icons/EventNote';
import OpacityIcon from '@material-ui/icons/Opacity';
import DirectionsIcon from '@material-ui/icons/Directions';
import LanguageIcon from '@material-ui/icons/Language';
import SubwayIcon from '@material-ui/icons/Subway';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ClearAll from '@material-ui/icons/ClearAll';
import TranslateIcon from '@material-ui/icons/Translate';
// NUEVO2
import {

    MenuItemLink,
    WithPermissions,

} from 'react-admin';

import PimsSubMenu from './PimsSubMenu';
import { translate } from 'react-admin';

function SquareFoot(props) {
    return (
        <SvgIcon {...props} style={{color: '#0F78BC'}}>
            <path d="M17.66,17.66l-1.06,1.06l-0.71-0.71l1.06-1.06l-1.94-1.94l-1.06,1.06l-0.71-0.71l1.06-1.06l-1.94-1.94l-1.06,1.06 l-0.71-0.71l1.06-1.06L9.7,9.7l-1.06,1.06l-0.71-0.71l1.06-1.06L7.05,7.05L5.99,8.11L5.28,7.4l1.06-1.06L4,4v14c0,1.1,0.9,2,2,2 h14L17.66,17.66z M7,17v-5.76L12.76,17H7z" />
        </SvgIcon>
    );
}

function AccountTreeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z" />
      </SvgIcon>
    );
}

function PermContactCalendarIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z" />
      </SvgIcon>
    );
}

function InventoryIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M20 2H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V20c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V8.7c.57-.35 1-.97 1-1.69V4c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V4l16-.02V7z" />
      </SvgIcon>
    );
}

function SegmentIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z" />
      </SvgIcon>
    );
}

function FenceIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M21 12v-2h-2V7l-3-3-2 2-2-2-2 2-2-2-3 3v3H3v2h2v2H3v2h2v4h14v-4h2v-2h-2v-2h2zm-5-5.17 1 1V10h-2V7.83l.41-.41.59-.59zm-4 0 .59.59.41.41V10h-2V7.83l.41-.41.59-.59zM11 14v-2h2v2h-2zm2 2v2h-2v-2h2zM7 7.83l1-1 .59.59.41.41V10H7V7.83zM7 12h2v2H7v-2zm0 4h2v2H7v-2zm10 2h-2v-2h2v2zm0-4h-2v-2h2v2z" />
      </SvgIcon>
    );
}

function FoundationIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M19 12h3L12 3 2 12h3v3H3v2h2v3h2v-3h4v3h2v-3h4v3h2v-3h2v-2h-2v-3zM7 15v-4.81l4-3.6V15H7zm6 0V6.59l4 3.6V15h-4z" />
      </SvgIcon>
    );
}

function WarehouseIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M22 21V7L12 3 2 7v14h5v-9h10v9h5zm-11-2H9v2h2v-2zm2-3h-2v2h2v-2zm2 3h-2v2h2v-2z" />
      </SvgIcon>
    );
}

function FourGMobiledataIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M9 7H7v5H5V7H3v7h4v3h2v-3h2v-2H9V7zm8 4v2h2v2h-5V9h7c0-1.1-.9-2-2-2h-5c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h5c1.1 0 2-.9 2-2v-4h-4z" />
      </SvgIcon>
    );
}

function AnalyticsIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z" />
      </SvgIcon>
    );
}

function AddRoadIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M20 18v-3h-2v3h-3v2h3v3h2v-3h3v-2zM18 4h2v9h-2zM4 4h2v16H4zm7 0h2v4h-2zm0 6h2v4h-2zm0 6h2v4h-2z" />
      </SvgIcon>
    );
}

function ManageAccountsIcon(props) {
    return (
      <SvgIcon {...props} >
        <circle cx="10" cy="8" r="4"></circle>
        <path d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26c-.79-1.13-1.26-2.51-1.26-4 0-1.07.25-2.07.67-2.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </SvgIcon>
    );
}

function PaidIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm.88 15.76V19h-1.75v-1.29c-.74-.18-2.39-.77-3.02-2.96l1.65-.67c.06.22.58 2.09 2.4 2.09.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31 0-.1.01-2.4 2.62-2.96V5h1.75v1.24c1.84.32 2.51 1.79 2.66 2.23l-1.58.67c-.11-.35-.59-1.34-1.9-1.34-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45 0 2.63-2.5 3.13-3.02 3.22z" />
      </SvgIcon>
    );
}

function PointOfSaleIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="M17 2H7c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 4H7V4h10v2zm3 16H4c-1.1 0-2-.9-2-2v-1h20v1c0 1.1-.9 2-2 2zm-1.47-11.81C18.21 9.47 17.49 9 16.7 9H7.3c-.79 0-1.51.47-1.83 1.19L2 18h20l-3.47-7.81zM9.5 16h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5z" />
      </SvgIcon>
    );
}

function NewspaperIcon(props) {
    return (
      <SvgIcon {...props} style={{color: '#0F78BC'}}>
        <path d="m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z" />
      </SvgIcon>
    );
}

function BallotIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M13 9.5h5v-2h-5v2zm0 7h5v-2h-5v2zm6 4.5H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM6 11h5V6H6v5zm1-4h3v3H7V7zM6 18h5v-5H6v5zm1-4h3v3H7v-3z" />
      </SvgIcon>
    );
}

function DirectionsBoatIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M20 21c-1.39 0-2.78-.47-4-1.32-2.44 1.71-5.56 1.71-8 0C6.78 20.53 5.39 21 4 21H2v2h2c1.38 0 2.74-.35 4-.99 2.52 1.29 5.48 1.29 8 0 1.26.65 2.62.99 4 .99h2v-2h-2zM3.95 19H4c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5L20 10.62V6c0-1.1-.9-2-2-2h-3V1H9v3H6c-1.1 0-2 .9-2 2v4.62l-1.29.42c-.26.08-.48.26-.6.5s-.15.52-.06.78L3.95 19zM6 6h12v3.97L12 8 6 9.97V6z" />
      </SvgIcon>
    );
}

function ApartmentIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z" />
      </SvgIcon>
    );
}

function PeopleAltIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87z" />
        <circle cx="9" cy="8" r="4" fill-rule="evenodd"></circle>
        <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24C14.5 5.27 15 6.58 15 8s-.5 2.73-1.33 3.76c.42.14.86.24 1.33.24zm-6 1c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
      </SvgIcon>
    );
}

function FmdBadIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm1 13h-2v-2h2v2zm0-4h-2V6h2v5z" />
      </SvgIcon>
    );
}

function ConstructionIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="m13.7826 15.1719 2.1213-2.1213 5.9963 5.9962-2.1213 2.1213zM17.5 10c1.93 0 3.5-1.57 3.5-3.5 0-.58-.16-1.12-.41-1.6l-2.7 2.7-1.49-1.49 2.7-2.7c-.48-.25-1.02-.41-1.6-.41C15.57 3 14 4.57 14 6.5c0 .41.08.8.21 1.16l-1.85 1.85-1.78-1.78.71-.71-1.41-1.41L12 3.49c-1.17-1.17-3.07-1.17-4.24 0L4.22 7.03l1.41 1.41H2.81l-.71.71 3.54 3.54.71-.71V9.15l1.41 1.41.71-.71 1.78 1.78-7.41 7.41 2.12 2.12L16.34 9.79c.36.13.75.21 1.16.21z" />
      </SvgIcon>
    );
}

function ElectricalService(props) {
    return (
      <SvgIcon {...props}>
        <path d="M21 14c0-.55-.45-1-1-1h-2v2h2c.55 0 1-.45 1-1zm-1 3h-2v2h2c.55 0 1-.45 1-1s-.45-1-1-1zm-8-3h-2v4h2c0 1.1.9 2 2 2h3v-8h-3c-1.1 0-2 .9-2 2z" />
        <path d="M5 13c0-1.1.9-2 2-2h1.5c1.93 0 3.5-1.57 3.5-3.5S10.43 4 8.5 4H5c-.55 0-1 .45-1 1s.45 1 1 1h3.5c.83 0 1.5.67 1.5 1.5S9.33 9 8.5 9H7c-2.21 0-4 1.79-4 4s1.79 4 4 4h2v-2H7c-1.1 0-2-.9-2-2z" />
      </SvgIcon>
    );
}

function CalendarViewDayIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M3 17h18v2H3zm0-7h18v5H3zm0-4h18v2H3z" />
      </SvgIcon>
    );
}

function WbShadeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M14 12v2.5l5.5 5.5H22zm0 8h3l-3-3zM8 4l-6 6h2v10h8V10h2L8 4zm1 10H7v-4h2v4z" />
      </SvgIcon>
    );
}

function UploadIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
      </SvgIcon>
    );
}

function ListIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
      </SvgIcon>
    );
}

function BuildIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="m22.7 19-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z" />
      </SvgIcon>
    );
}

function FileDownloadIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
      </SvgIcon>
    );
}

function DriveFolderUploadIcon (props) {
    return (
      <SvgIcon {...props}>
        <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10zM8 13.01l1.41 1.41L11 12.84V17h2v-4.16l1.59 1.59L16 13.01 12.01 9 8 13.01z" />
      </SvgIcon>
    );
}

function SettingsSuggestIcon (props) {
    return (
      <SvgIcon {...props}>
        <path d="M17.41 6.59 15 5.5l2.41-1.09L18.5 2l1.09 2.41L22 5.5l-2.41 1.09L18.5 9l-1.09-2.41zm3.87 6.13L20.5 11l-.78 1.72-1.72.78 1.72.78.78 1.72.78-1.72L23 13.5l-1.72-.78zm-5.04 1.65 1.94 1.47-2.5 4.33-2.24-.94c-.2.13-.42.26-.64.37l-.3 2.4h-5l-.3-2.41c-.22-.11-.43-.23-.64-.37l-2.24.94-2.5-4.33 1.94-1.47c-.01-.11-.01-.24-.01-.36s0-.25.01-.37l-1.94-1.47 2.5-4.33 2.24.94c.2-.13.42-.26.64-.37L7.5 6h5l.3 2.41c.22.11.43.23.64.37l2.24-.94 2.5 4.33-1.94 1.47c.01.12.01.24.01.37s0 .24-.01.36zM13 14c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3z" />
      </SvgIcon>
    );
}

function ScreenshotMonitorIcon (props) {
    return (
      <SvgIcon {...props}>
        <path d="M20 3H4c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 14H4V5h16v12z" />
        <path d="M6.5 7.5H9V6H5v4h1.5zM19 12h-1.5v2.5H15V16h4z" />
      </SvgIcon>
    );
}

function PlaylistAddCheckCircleIcon (props) {
    return (
      <SvgIcon {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7 7h7v2H7V7zm0 3h7v2H7v-2zm3 5H7v-2h3v2zm4.05 3.36-2.83-2.83 1.41-1.41 1.41 1.41L17.59 12 19 13.41l-4.95 4.95z" />
      </SvgIcon>
    );
}

const colorIconos = '#0F78BC';


class PimsMenu extends Component {
    state = {
        menuAdmin: false,
        menuInventario: false,
        menuInicio: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        // logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { translate } = this.props;
        const { onMenuClick, open, logout } = this.props;
        var usuarios = localStorage.getItem('usuarios');
        var perfiles = localStorage.getItem('perfiles');
        // var asigclaves=localStorage.getItem('asigclaves');
        var proyecto = localStorage.getItem('proyecto');
        var atributos = [];
        let role = localStorage.getItem('permissions');
        atributos = JSON.parse(role);
        if (!localStorage.getItem('visibility')) {
            localStorage.setItem("visibility", "hidden");
        }
        return (
          <div id="pimsMenu">
            {' '}
              <WithPermissions
                render={({ permissions }) => (
                  atributos['licencias'] != 3
                    ? <MenuItemLink to={`/licencia_clientes`} primaryText={translate('dynatest.resources.pimsMenu.fields.clientes')} leftIcon={<AccountTreeIcon style={{color: '#0F78BC'}}/>} onClick={onMenuClick} />
                    : null
                )}
              />
              
                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuAdmin')}
                    isOpen={this.state.menuAdmin}
                    sidebarIsOpen={open}
                    name="dynatest.resources.pimsMenu.fields.configuraciones"
                    icon={<SettingsSuggestIcon />}
                >
                    <PimsSubMenu
                        name="dynatest.resources.pimsMenu.subMenu.usuarios"
                        icon={<PeopleAltIcon />}
                        handleToggle={() => this.handleToggle('menuUsuario')}
                        isOpen={this.state.menuUsuario}
                        sidebarIsOpen={open}
                    >
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['usuario'] != 3
                                    ? <MenuItemLink to={`/usuarios`} primaryText={translate('dynatest.resources.pimsMenu.subMenu.usuarios')} leftIcon={<PersonAdd style={{color: '#0F78BC'}} />} onClick={onMenuClick} />
                                    : null
                            )}
                        />
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['perfiles'] != 3
                                    ? <MenuItemLink to={`/atributos`} style={{whiteSpace: 'pre-line', height: 'auto'}} primaryText={translate('dynatest.resources.pimsMenu.subMenu.atributos')} leftIcon={<AssignmentInd style={{color: '#0F78BC'}} />} onClick={onMenuClick} />
                                    : null
                            )}
                        />

                    </PimsSubMenu>
                 </PimsSubMenu>
                {logout}
            </div>

        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    /*theme: state.theme,
    locale: state.i18n.locale,*/
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
);

export default translate(enhance(PimsMenu));


import React from 'react';
import { Create as ReactAdminCreate, CardActions, ListButton, Toolbar, SaveButton} from 'react-admin';
import { withStyles } from '@material-ui/core';
import BackButton from '../BotonCancelar'
const PimsCreateActions = ({
    basePath,
    className,
    hasList,
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}        
    </CardActions>
);


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const PimsToolbarActions = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <BackButton />
    </Toolbar>
));

export const PimsCreate = ReactAdminCreate;

PimsCreate.defaultProps = {
   actions: <PimsCreateActions /> ,   
   toolbar : <PimsToolbarActions/>
};

export default PimsCreate;